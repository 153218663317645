import React from "react";

const AboutUs = () => {
  return (
    <div className="container">
      <h1>About Us</h1>
      <p>
        Gratif is an on-demand photography and video production marketplace that
        enables you to find top professionals for the job at the best prices.
      </p>
      <p>Gratif is built by India's top photographers and tech developers.</p>
      <br />
      <h4 style={{ marginBottom: "0px" }}>Mountainscale Private Limited</h4>
      <p>
        No : 5-B, 2nd floor, 11th Cross, RMV Extension, Sadashiva Nagar,
        Bengaluru, Karnataka 560080
      </p>
    </div>
  );
};

export default AboutUs;
