const CustomRadio = ({
  label,
  value,
  selected,
  onChange,
  addDefaultMargin,
  required,
  name,
}) => {
  const labelClasses = `custom-radio ${selected ? "selected" : ""} fw-500 ${
    addDefaultMargin ? "mb-3" : ""
  }`;

  return (
    <label className={labelClasses}>
      {label}
      <input
        type="radio"
        name={name}
        value={value}
        checked={selected}
        onChange={() => onChange(value)}
        required={required}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default CustomRadio;
