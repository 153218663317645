import React from "react";

const FAQItem = ({ question, answer, expanded, toggle }) => {
  return (
    <div
      style={{
        marginBottom: "20px",
        borderBottom: "2px solid black",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={toggle}
      >
        <div
          style={{
            fontWeight: "700",
          }}
        >
          {question}
        </div>
        {expanded ? (
          <span
            role="img"
            aria-label="Collapse"
            style={{ color: "var(--orange-color)", fontSize: "30px" }}
          >
            -
          </span>
        ) : (
          <span
            role="img"
            aria-label="Expand"
            style={{ color: "var(--orange-color)", fontSize: "30px" }}
          >
            +
          </span>
        )}
      </div>
      {expanded && (
        <div style={{ padding: "10px", fontWeight: "500" }}>{answer}</div>
      )}
    </div>
  );
};

export default FAQItem;
