import React from "react";
import { Link } from "react-router-dom";
import girlWithCamera from "../../assets/images/girl_with_camera.png";
import logo from "../../assets/images/gratif_logo.png";

const JoinPage = () => {
  return (
    <>
      <div className="container">
        <div
          style={{
            padding: "1em 0em 2.5em",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="header-logo">
            <Link to="/">
              <img className="gratif-logo-image" src={logo} alt="Gratif Logo" />
            </Link>
          </div>
        </div>
        <div className="home-top-container mt-5">
          <div className="home-top-left">
            <h1>You are one step</h1>
            <h1>away from being a</h1>
            <h1>part of greatness</h1>
            <p>
              Ensure your portfolio is prepared before initiating the
              application process. Towards the conclusion, you'll have the
              opportunity to upload a maximum of 25 images and 15 YouTube links
              that align with the services you've chosen in the subsequent step.
            </p>
            <Link to="/personal-details" className="home-join-button">
              Continue to application
            </Link>
          </div>
          <div className="home-top-right">
            <img
              className="home-globe-image"
              src={girlWithCamera}
              alt="Home Globe"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinPage;
