import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import AppUrls from "../../api/AppUrls";

const AccountDeletionPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    apiClient
      .request(AppUrls.contactUsUrl, "POST", {
        name: name,
        email: email,
        phone: phone,
        type: "From account deletion website",
        message: message,
      })
      .then((response) => {
        console.log(response);
        if (response !== undefined) {
          toast.success("Message sent successfully");
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Container style={{ marginTop: "50px" }}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={8}>
            <h1>Delete Your Account</h1>
            <p>
              Before you proceed, please take a moment to read through the
              following information. Deleting your account will have the
              following effect:
            </p>
            <ul>
              <li>
                Loss of Data: All your account information, including settings,
                preferences, wallet points will be permanently deleted.
              </li>
              <li>
                Access Termination: You will lose access to our platform, and
                any subscriptions or premium features associated with your
                account will be discontinued.
              </li>
              <li>
                Data retention: Please note that while we will promptly delete
                your account, certain data may be retained for a specific
                period. This includes anonymized analytics data and any
                information required for legal compliance.
              </li>
            </ul>
            <p>
              Proceed with Account Deletion We will review the request and
              initiate this process shortly. This may take up to 5 working days.
              You will receive a confirmation mail once the process is
              completed.
            </p>

            <hr />
            <h1>Account Delete Request</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                controlId="formGroupName"
                style={{ marginBottom: "20px" }}
              >
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupEmail"
                style={{ marginBottom: "20px" }}
              >
                <Form.Label>Email address *</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupPhone"
                style={{ marginBottom: "20px" }}
              >
                <Form.Label>Phone Number *</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter phone number"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupMessage"
                style={{ marginBottom: "20px" }}
              >
                <Form.Label>Message *</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>

              <Button variant="danger" type="submit" disabled={isLoading}>
                {isLoading ? "Sending Request..." : "Send Request"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default AccountDeletionPage;
