import React from "react";
import { Link } from "react-router-dom";
import appleIcon from "../../../assets/images/apple_icon.png";
import playstoreIcon from "../../../assets/images/playstore_icon.png";
import "./Footer.css";
const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-links">
        <Link to="/contact-us">Contact Us</Link>
        <Link to="/terms">Terms and Conditions</Link>
        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/cancellation">Refund and Cancellation</Link>
        <Link to="/about-us">About Us</Link>
      </div>
      <div className="footer-download-container">
        <div className="download-text">Download the app</div>
        <div className="button-container">
          <button className="google-play-button">
            <img
              className="footer-image"
              src={playstoreIcon}
              alt="Play Store Icon"
            />
            <div className="button-text-container">
              <div className="button-text-start">GET IT ON</div>
              <div className="button-text">Google Play</div>
            </div>
          </button>
          <button className="apple-app-button">
            <img className="footer-image" src={appleIcon} alt="Apple Icon" />
            <div className="button-text-container">
              <div className="button-text-start">GET IT ON</div>
              <div className="button-text">App Store</div>
            </div>
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
