import { toast } from "react-toastify";
import apiClient from "../api/ApiClients";
import AppUrls from "../api/AppUrls";

import {
  ONBOARDING_DATA_FAILURE,
  ONBOARDING_DATA_REQUEST,
  ONBOARDING_DATA_SUCCESS,
} from "../types/dataTypes";

export const addonBoardingData = (formData) => {
  return (dispatch) => {
    dispatch({ type: ONBOARDING_DATA_REQUEST });
    apiClient
      .request(AppUrls.photographerUrl, "POST", formData, true)
      .then((response) => {
        if (response !== undefined) {
          dispatch({ type: ONBOARDING_DATA_SUCCESS });
          toast.success("Application submitted successfully!");
          setTimeout(function () {
            window.location.href = "/onboarding-success";
          }, 2000);
        } else {
          dispatch({
            type: ONBOARDING_DATA_FAILURE,
            payload: "Response not found",
          });
        }
      })
      .catch((error) => {
        dispatch({ type: ONBOARDING_DATA_FAILURE, payload: error });
      });
  };
};
