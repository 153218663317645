import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import profilePlaceholder from "../../../assets/images/profile_placeholder.png";
import { ONBOARDING_DATA_UPDATE } from "../../../types/dataTypes";
import OnBoardingHeader from "../OnBoardingHeader/OnBoardingHeader";

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxFormData = useSelector((state) => state.onboardingData.data) || {};

  const [formData, setFormData] = useState(reduxFormData);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.profileImage) {
      toast.error("Please upload your profile picture");
      return;
    }
    dispatch({ type: ONBOARDING_DATA_UPDATE, payload: formData });
    navigate("/skill-details");
  };

  const [profileImage, setProfileImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setProfileImage(URL.createObjectURL(img));
      setFormData({ ...formData, profileImage: URL.createObjectURL(img) });
    }
  };

  const states = [
    "Andhra Pradesh",
    "Assam",
    "Gujarat",
    "Haryana",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Rajasthan",
    "Tamil Nadu",
    "Telangana",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Union Territory",
  ];

  const cities = {
    Maharashtra: ["Mumbai", "Pune", "Nagpur", "Thane"],
    "Union Territory": ["Delhi", "Chandigarh"],
    Karnataka: ["Bengaluru", "Mangalore", "Mysuru", "Udupi"],
    Telangana: ["Hyderabad"],
    "Tamil Nadu": ["Chennai", "Coimbatore"],
    "West Bengal": ["Kolkata"],
    Gujarat: ["Ahmedabad", "Surat", "Vadodara"],
    Rajasthan: ["Jaipur"],
    "Uttar Pradesh": ["Lucknow", "Kanpur"],
    "Madhya Pradesh": ["Indore", "Bhopal"],
    "Andhra Pradesh": ["Tirupati", "Visakhapatnam"],
    Uttarakhand: ["Dehradun"],
    Haryana: ["Gurgaon"],
    Assam: ["Guwahati"],
    Kerala: ["Kochi"],
  };

  return (
    <>
      <OnBoardingHeader
        nextLink="/skill-details"
        progressBarValue={20}
        breadcrumbStatus={["Home"]}
      />

      <div className="container mt-5">
        <h2>Lets Get to Know You</h2>

        <form onSubmit={handleSubmit}>
          <div className="text-center">
            <label htmlFor="profileImageUpload">
              <img
                src={
                  profileImage || formData.profileImage || profilePlaceholder
                }
                alt="Profile"
                style={{
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              />
            </label>
            <p>
              {profileImage || formData.profileImage
                ? "Profile picture uploaded (Click on the image if you wish to change it)"
                : "Upload your recent profile photo"}
            </p>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="profileImageUpload"
            />
          </div>
          <div className="mb-3 mt-5">
            <label htmlFor="name" className="form-label  fs-5">
              Name
            </label>
            <input
              type="text"
              className="form-control py-3"
              id="name"
              placeholder="Enter your name as it appears on your government ID"
              // onChange={handleInputChange}
              onChange={(e) => {
                const { value } = e.target;
                const regex = /^[a-zA-Z ]*$/;

                if (regex.test(value)) {
                  handleInputChange(e);
                }
              }}
              value={formData.name || ""}
              required
            />
          </div>

          <div className="mb-3 mt-4">
            <label htmlFor="email" className="form-label fs-5">
              Email
            </label>
            <input
              type="email"
              className="form-control py-3"
              id="email"
              placeholder="Enter your work email"
              onChange={handleInputChange}
              value={formData.email || ""}
              required
            />
          </div>

          <div className="mb-3 mt-4">
            <label htmlFor="mobile" className="form-label  fs-5">
              Mobile Number
            </label>
            <div className="row">
              <div className="col-md-2">
                <select
                  className="form-select py-3"
                  id="countryCode"
                  onChange={handleInputChange}
                  value={formData.countryCode || "+91"}
                >
                  <option value="+91">India (+91)</option>
                  {/* Add other country codes as needed */}
                </select>
              </div>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control py-3"
                  id="mobileNumber"
                  placeholder="Enter your mobile number"
                  onChange={(e) => {
                    const { value } = e.target;
                    const regex = /^\d{0,10}$/;

                    if (regex.test(value)) {
                      handleInputChange(e);
                    }
                  }}
                  value={formData.mobileNumber || ""}
                  required
                />
              </div>
            </div>
          </div>

          <div className="mb-3 mt-4">
            <label htmlFor="address" className="form-label fs-5">
              Address
            </label>
            <input
              className="form-control py-3"
              id="address"
              placeholder="Enter your address as it appears on your government ID"
              onChange={handleInputChange}
              value={formData.address || ""}
              required
            />
          </div>

          <div className="mb-3 row mt-4">
            <div className="col-md-6">
              <label htmlFor="state" className="form-label  fs-5">
                State
              </label>
              <select
                className="form-select py-3"
                id="state"
                onChange={handleInputChange}
                value={formData.state || ""}
                required
              >
                <option value="" disabled>
                  Select state
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-6">
              <label htmlFor="city" className="form-label fs-5">
                City
              </label>
              <select
                className="form-select py-3"
                id="city"
                onChange={handleInputChange}
                value={formData.city || ""}
                required
                disabled={!formData.state}
              >
                <option value="" disabled>
                  Select city
                </option>
                {formData.state &&
                  cities[formData.state].map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-3 mt-5">
              <label htmlFor="businessName" className="form-label  fs-5">
                Enter your GST details
              </label>
              <input
                type="text"
                className="form-control py-3"
                id="businessName"
                placeholder="Name of the business"
                onChange={handleInputChange}
                value={formData.businessName || ""}
              />
              <input
                className="form-control py-3 mt-3"
                id="gstNumber"
                placeholder="Add GST number"
                onChange={handleInputChange}
                value={formData.gstNumber || ""}
              />
            </div>
            <div className="mb-3 mt-4">
              <label htmlFor="languages" className="form-label  fs-5">
                Specify the languages you speak fluently, separated by commas*
              </label>
              <input
                type="text"
                className="form-control py-3"
                id="languages"
                onChange={(e) => {
                  const { value } = e.target;
                  const regex = /^[a-zA-Z ,]*$/;

                  if (regex.test(value)) {
                    handleInputChange(e);
                  }
                }}
                value={formData.languages || ""}
                required
              />
            </div>

            {/* <Link
              to={{
                pathname: "/skill-details",
                state: { formData },
              }}
              className="orange-button-onboard-mobile mt-4"
            >
              Next
            </Link> */}
            <button type="submit" className="orange-button-onboard-mobile mt-4">
              Next
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default PersonalDetails;
