import React from "react";
import { Link } from "react-router-dom";
import greenTick from "../../../assets/images/green_tick.png";

const OnBoardingSuccess = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <img
          src={greenTick}
          alt="Success"
          style={{ height: "100px", width: "100px" }}
        />
        <p style={{ fontWeight: 600, textAlign: "center", marginTop: "20px" }}>
          We appreciate you sharing your talent! Your portfolio is under careful
          review by our team, and if selected, you can anticipate a response
          within 10 days via email.
        </p>
        <Link
          to="/"
          style={{
            color: "var(--orange-color)",
            fontWeight: "bold",
            display: "block",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          Go back to home
        </Link>
      </div>
    </>
  );
};

export default OnBoardingSuccess;
