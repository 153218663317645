import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CustomRadio from "../../../components/common/CustomRadio/CustomRadio";
import { ONBOARDING_DATA_UPDATE } from "../../../types/dataTypes";
import OnBoardingHeader from "../OnBoardingHeader/OnBoardingHeader";

const GearDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxFormData = useSelector((state) => state.onboardingData.data) || {};

  const [formData, setFormData] = useState(reduxFormData);
  const [cameraSupportOther, setCameraSupportOther] = useState(
    reduxFormData.tempData.cameraSupport || ""
  );
  const [photoEditingToolOther, setPhotoEditingToolOther] = useState(
    reduxFormData.tempData.photoEditingTool || ""
  );

  // Update the local state on input change
  const handleCameraSupportChange = (e) => {
    setCameraSupportOther(e.target.value);
  };

  const handlePhotoEditingToolChange = (e) => {
    setPhotoEditingToolOther(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.cameraSupport.length) {
      toast.error("Camera Support is required");
      return;
    }

    if (!formData.photoEditingTools.length) {
      toast.error("Photo Editing Tools are required");
      return;
    }

    if (!formData.videoEditingTools.length) {
      toast.error("Video Editing Tools are required");
      return;
    }

    dispatch({ type: ONBOARDING_DATA_UPDATE, payload: formData });
    navigate("/timing-preference");
  };

  const cameraBodyToolTipText =
    "Enter the name of the company followed by camera model";

  const cameraLensToolTipText =
    "Enter company name followed by focal length. E.g. Nikon 70-200 F2.8";

  const micsToolTipText = "e.g. Rode Wireless Go 2, Blue Yeti";

  const lightsToolTipText =
    "e.g. Amaran 200x Bi-Color LED Light, Godox SL 200 bi";

  const handleAddMoreCamera = () => {
    const newCamera = {
      id: formData.cameraBodyAndType.length + 1,
      cameraBody: "",
      bodyType: "",
    };
    setFormData({
      ...formData,
      cameraBodyAndType: [...formData.cameraBodyAndType, newCamera],
    });
  };

  const handleRemoveCamera = (id) => {
    const updatedCameraList = formData.cameraBodyAndType.filter(
      (camera) => camera.id !== id
    );
    const updatedCameraListWithNewIds = updatedCameraList.map(
      (camera, index) => ({
        ...camera,
        id: index + 1,
      })
    );
    setFormData({
      ...formData,
      cameraBodyAndType: updatedCameraListWithNewIds,
    });
  };

  const handleCameraInputChange = (id, field, value) => {
    const updatedCameraList = formData.cameraBodyAndType.map((camera) =>
      camera.id === id ? { ...camera, [field]: value } : camera
    );
    setFormData({
      ...formData,
      cameraBodyAndType: updatedCameraList,
    });
  };

  const GearLabel = ({ text, selected, onClick }) => (
    <div
      style={{
        width: "100%",
        display: "inline-block",
        marginRight: "10px",
        marginBottom: "10px",
        padding: "18px 0",
        borderRadius: "8px",
        border: `3px solid ${selected ? "black" : "#B0B0B0"}`,
        fontWeight: "600",
        textAlign: "center",
      }}
      onClick={onClick}
    >
      {text}
    </div>
  );

  const cameraSupportLabels = [
    ["Drone", "360 Camera"],
    ["Gimbal", "Softbox"],
    ["Diffusers", "Strobes"],
    ["Tripods", "Monopod"],
    ["Dolly", "Motorised dolly"],
    ["Baby portfolio props", "Backgrounds"],
    ["Photography backdrops", "Other"],
  ];

  const photoEditingLabels = [
    ["Photoshop", "Lightroom"],
    ["Luminar", "Other"],
  ];

  const videoEditingLabels = [
    ["Premiere Pro", "Davinci Resolve", "Final Cut Pro", "Other"],
  ];

  return (
    <>
      <OnBoardingHeader
        nextLink="/timing-preference"
        progressBarValue={60}
        breadcrumbStatus={["Home", "Personal Details", "Skill Details"]}
      />
      <form onSubmit={handleSubmit}>
        <div className="container mt-5">
          <h2>Tell us more about your treasured gear</h2>

          <h4 className="heading mt-5">CAMERA</h4>
          <div className="row mt-5">
            <div className="col-md-6">
              <label htmlFor={`cameraBody`} className="form-label fs-5">
                Camera Body
              </label>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>{cameraBodyToolTipText}</Tooltip>
                }
              >
                <i
                  className="bi bi-info-circle"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                ></i>
              </OverlayTrigger>
            </div>

            <div className="col-md-6">
              <label htmlFor={`bodyType`} className="form-label fs-5">
                Body Type
              </label>
            </div>
          </div>

          {formData.cameraBodyAndType.map((gear, index) => (
            <div className={`row ${index > 0 ? "mt-4" : ""}`} key={gear.id}>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control py-3"
                  id={`cameraBody${gear.id}`}
                  placeholder="Enter camera body"
                  value={gear.cameraBody}
                  required
                  onChange={(e) =>
                    handleCameraInputChange(
                      gear.id,
                      "cameraBody",
                      e.target.value
                    )
                  }
                />
              </div>

              <div className="col-md-6">
                <select
                  className="form-select py-3"
                  id={`bodyType${gear.id}`}
                  value={gear.bodyType}
                  required
                  onChange={(e) =>
                    handleCameraInputChange(gear.id, "bodyType", e.target.value)
                  }
                >
                  <option value="" disabled>
                    Select body type
                  </option>
                  <option value="fullFrame">Full frame</option>
                  <option value="cropSensor">Crop sensor</option>
                  <option value="other">Other</option>
                </select>
              </div>

              {formData.cameraBodyAndType.length > 1 && (
                <div className="mt-2">
                  <h2
                    style={{
                      color: "var(--orange-color)",
                      fontSize: "18px",
                      cursor: "pointer",
                      textAlign: "end",
                    }}
                    onClick={() => handleRemoveCamera(gear.id)}
                  >
                    Remove
                  </h2>
                </div>
              )}
            </div>
          ))}

          <h2 className="mt-3 add-more" onClick={handleAddMoreCamera}>
            <i className="bi bi-plus">ADD MORE</i>
          </h2>

          <div>
            <label className="form-label fs-5 mt-4">
              Select camera support gear and other accessories
            </label>
            {cameraSupportLabels.map((pair, index) => (
              <div
                key={index}
                className="row mt-3"
                style={{ cursor: "pointer" }}
              >
                {pair.map((gear, idx) => (
                  <div key={idx} className="col-md-6">
                    <GearLabel
                      text={gear}
                      selected={
                        formData.cameraSupport &&
                        formData.cameraSupport.includes(gear)
                      }
                      onClick={() =>
                        setFormData((prevData) => {
                          const tools = prevData.cameraSupport || [];
                          if (tools.includes(gear)) {
                            if (gear === "Other") {
                              return {
                                ...prevData,
                                tempData: {
                                  ...prevData.tempData,
                                  cameraSupport: "",
                                },
                                cameraSupport: tools.filter(
                                  (tool) =>
                                    tool !== formData.tempData.cameraSupport &&
                                    tool !== "Other"
                                ),
                              };
                            }
                            return {
                              ...prevData,
                              cameraSupport: tools.filter(
                                (tool) => tool !== gear
                              ),
                            };
                          } else {
                            return {
                              ...prevData,
                              cameraSupport: [...tools, gear],
                            };
                          }
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
            {formData.cameraSupport &&
              formData.cameraSupport.includes("Other") && (
                <input
                  type="text"
                  className="form-control py-3 mt-3"
                  placeholder="Mention any other support gear you have"
                  value={cameraSupportOther}
                  required
                  onChange={handleCameraSupportChange}
                  onBlur={(e) =>
                    setFormData((prevData) => {
                      let tools = prevData.cameraSupport || [];
                      if (
                        prevData.tempData &&
                        prevData.tempData.cameraSupport
                      ) {
                        tools = tools.filter(
                          (tool) => tool !== prevData.tempData.cameraSupport
                        );
                      }
                      return {
                        ...prevData,
                        cameraSupport: [...tools, e.target.value],
                        tempData: {
                          ...prevData.tempData,
                          cameraSupport: e.target.value,
                        },
                      };
                    })
                  }
                />
              )}
          </div>
          <div className="mb-3 mt-5">
            <label className="form-label  fs-5">Camera Lenses</label>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>{cameraLensToolTipText}</Tooltip>
              }
            >
              <i
                className="bi bi-info-circle"
                style={{ marginLeft: "10px", cursor: "pointer" }}
              ></i>
            </OverlayTrigger>
            <input
              type="text"
              className="form-control py-3"
              placeholder="Mention whether its wide angle,standard zoom etc, followed by aperture"
              value={formData.cameraLens || ""}
              required
              onChange={(event) =>
                setFormData({
                  ...formData,
                  cameraLens: event.target.value,
                })
              }
            />
          </div>
          <div className="divider mb-4"></div>
          <h4 className="heading">AUDIO AND LIGHTS</h4>
          <div className="mb-3 mt-5">
            <label className="form-label fs-5">Mics</label>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip id={`tooltip-top`}>{micsToolTipText}</Tooltip>}
            >
              <i
                className="bi bi-info-circle"
                style={{ marginLeft: "10px", cursor: "pointer" }}
              ></i>
            </OverlayTrigger>
            <input
              type="text"
              className="form-control py-3"
              placeholder="Mention the mics you have"
              value={formData.mics || ""}
              required
              onChange={(event) =>
                setFormData({
                  ...formData,
                  mics: event.target.value,
                })
              }
            />
          </div>
          <div className="mb-3 mt-4">
            <label className="form-label  fs-5">Lights</label>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>{lightsToolTipText}</Tooltip>
              }
            >
              <i
                className="bi bi-info-circle"
                style={{ marginLeft: "10px", cursor: "pointer" }}
              ></i>
            </OverlayTrigger>
            <input
              type="text"
              className="form-control py-3"
              placeholder="Mention the flash kits and video lights"
              value={formData.lights || ""}
              required
              onChange={(event) =>
                setFormData({
                  ...formData,
                  lights: event.target.value,
                })
              }
            />
          </div>
          <div className="mt-4">
            <label
              className="form-label fs-5 fw-500"
              style={{ marginBottom: "0px" }}
            >
              Do the flash kits have High Speed Sync?
            </label>
            <p>Answer if you have added flash kit in the gear above</p>
            <div className="custom-radio-group">
              <CustomRadio
                label="Yes"
                value="yes"
                name="highSpeedSync"
                selected={formData.highSpeedSync === "yes"}
                required
                onChange={() =>
                  setFormData({
                    ...formData,
                    highSpeedSync: "yes",
                  })
                }
              />
              <CustomRadio
                label="No"
                value="no"
                name="highSpeedSync"
                selected={formData.highSpeedSync === "no"}
                required
                onChange={() =>
                  setFormData({
                    ...formData,
                    highSpeedSync: "no",
                  })
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <label className="form-label fs-5 mb-3 fw-500">
              Confirm if you have extra batteries for the primary camera
            </label>
            <div className="custom-radio-group">
              <CustomRadio
                label="Yes"
                value="yes"
                name="extraBattery"
                selected={formData.extraBattery === "yes"}
                onChange={() =>
                  setFormData({
                    ...formData,
                    extraBattery: "yes",
                  })
                }
              />
              <CustomRadio
                label="No"
                value="no"
                name="extraBattery"
                selected={formData.extraBattery === "no"}
                onChange={() =>
                  setFormData({
                    ...formData,
                    extraBattery: "no",
                  })
                }
              />
            </div>
          </div>
          <div className="divider mb-4"></div>
          <h4 className="heading">EDITING</h4>
          <div>
            <label className="form-label fs-5 mt-4">
              Select the photo editing tools you use
            </label>
            {photoEditingLabels.map((pair, index) => (
              <div
                key={index}
                className="row mt-3"
                style={{ cursor: "pointer" }}
              >
                {pair.map((gear, idx) => (
                  <div key={idx} className="col-md-6">
                    <GearLabel
                      text={gear}
                      selected={
                        formData.photoEditingTools &&
                        formData.photoEditingTools.includes(gear)
                      }
                      onClick={() =>
                        setFormData((prevData) => {
                          const tools = prevData.photoEditingTools || [];
                          if (tools.includes(gear)) {
                            // If the gear is already selected, remove it
                            if (gear === "Other") {
                              return {
                                ...prevData,
                                tempData: {
                                  ...prevData.tempData,
                                  photoEditingTool: "",
                                },
                                photoEditingTools: tools.filter(
                                  (tool) =>
                                    tool !==
                                      formData.tempData.photoEditingTool &&
                                    tool !== "Other"
                                ),
                              };
                            }
                            return {
                              ...prevData,
                              photoEditingTools: tools.filter(
                                (tool) => tool !== gear
                              ),
                            };
                          } else {
                            // If the gear is not selected, add it
                            return {
                              ...prevData,
                              photoEditingTools: [...tools, gear],
                            };
                          }
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
            {formData.photoEditingTools &&
              formData.photoEditingTools.includes("Other") && (
                <input
                  type="text"
                  className="form-control py-3 mt-3"
                  placeholder="Mention the name of the photo editing tool"
                  required
                  value={photoEditingToolOther}
                  onChange={handlePhotoEditingToolChange}
                  onBlur={(e) =>
                    setFormData((prevData) => {
                      let tools = prevData.photoEditingTools || [];
                      if (
                        prevData.tempData &&
                        prevData.tempData.photoEditingTool
                      ) {
                        tools = tools.filter(
                          (tool) => tool !== prevData.tempData.photoEditingTool
                        );
                      }
                      return {
                        ...prevData,
                        photoEditingTools: [...tools, e.target.value],
                        tempData: {
                          ...prevData.tempData,
                          photoEditingTool: e.target.value,
                        },
                      };
                    })
                  }
                />
              )}
          </div>
          <div className="mb-5 mt-4">
            <label className="form-label fs-5 mt-4 ">
              Select the video editing tools you use
            </label>
            {videoEditingLabels.map((pair, index) => (
              <div
                key={index}
                className="row mt-3"
                style={{ cursor: "pointer" }}
              >
                {pair.map((gear, idx) => (
                  <div key={idx} className="col-md-6">
                    <GearLabel
                      text={gear}
                      selected={
                        formData.videoEditingTools &&
                        formData.videoEditingTools.includes(gear)
                      }
                      onClick={() =>
                        setFormData((prevData) => {
                          const tools = prevData.videoEditingTools || [];
                          if (tools.includes(gear)) {
                            // If the gear is already selected, remove it
                            return {
                              ...prevData,
                              videoEditingTools: tools.filter(
                                (tool) => tool !== gear
                              ),
                            };
                          } else {
                            // If the gear is not selected, add it
                            return {
                              ...prevData,
                              videoEditingTools: [...tools, gear],
                            };
                          }
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <button type="submit" className="orange-button-onboard-mobile mt-4">
            Next
          </button>
        </div>
      </form>
      <ToastContainer />
    </>
  );
};

export default GearDetails;
