import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import clock from "../../assets/images/clock.png";
import faq from "../../assets/images/faq.png";
import homeGlobe from "../../assets/images/home_globe.png";
import model from "../../assets/images/model.png";
import pay from "../../assets/images/pay.png";
import separator from "../../assets/images/separator.png";
import wand from "../../assets/images/wand.png";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import FAQItem from "./FaqItem";
import "./HomePage.css";

const HomePage = () => {
  const [expandedFAQ, setExpandedFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };
  const faqs = [
    {
      question: "How do I join the photography platform?",
      answer:
        "To join our photography platform, simply visit our website and follow the easy onboarding process. Fill in your details, upload samples of your work, and submit your application.",
    },
    {
      question:
        "What types of photography services can I offer on the platform?",
      answer:
        "You can offer a variety of photography services, including but not limited to Pre wedding shoots, Linkedin headshots, event , product shoots, and more. During onboarding, you can select up to five different services that best showcase your skills.",
    },
    {
      question: "How does the approval process work?",
      answer:
        "Once you submit your application, our team will review your profile and portfolio. We aim to approve applications promptly. You will receive a notification regarding the status of your application.",
    },
    {
      question: "Is there a fee to join the photography platform?",
      answer:
        "Joining our platform is free of charge. There are no upfront fees, and we operate on a commission-based model, ensuring a fair and mutually beneficial partnership. Professional gets 90% of the fee and Gratif charges a 10% commission.",
    },
    {
      question: "How do I receive payments for my photography services?",
      answer:
        "Payments are processed securely through our platform. Once you complete a project, your earnings will be deposited directly into your designated account.",
    },
    {
      question:
        "Can I collaborate with clients directly to discuss project details?",
      answer:
        "To avoid any disturbance when you’re working on another project, we will assign an in-house planner who will be the point of contact between you and the user.",
    },
    {
      question:
        "Can I set my own pricing for photography services? (In next version)",
      answer:
        "Partners Level 1-3 will work at a prefixed market price, while partners Level 4 & 5 can set their own price.",
    },
  ];

  return (
    <>
      <Header />
      <div className="container">
        <div className="home-top-container">
          <div className="home-top-left">
            <h1>Thousands of</h1>
            <h1>photographers</h1>
            <h1>trust Gratif</h1>
            <p>
              Join the elite league of world's top photography and video
              production professionals and elevate your business on India's
              leading on-demand photography super app .
            </p>
            <Link
              to="/join"
              className="home-join-button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Submit your application{" "}
              <FaArrowRight style={{ marginLeft: "5px", marginTop: "1px" }} />
            </Link>
          </div>
          <div className="home-top-right">
            <img
              className="home-globe-image"
              src={homeGlobe}
              alt="Home Globe"
            />
          </div>
        </div>
        <div className="home-separator">
          <img
            className="home-separator-image"
            src={separator}
            alt="Home Separator"
          />
          <h1 className="home-separator-h1">Why join Us!</h1>
          <img
            className="home-separator-image"
            src={separator}
            alt="Home Separator"
          />
        </div>
        <div className="container text-center mt-5">
          <div className="row">
            <div className="col-md-4">
              <img
                src={wand}
                className="img-fluid home-join-img mb-3"
                alt="wand"
              />
              <h4>Tools that help you focus on your craft</h4>
              <p className="join-p">
                Smart AI editor reduces your editor time by 80%. Discard
                duplicates, blur pictures and accidental pictures at the click
                of a button.
              </p>
              <p className="join-p">Coming soon</p>
            </div>

            <div className="col-md-4">
              <img
                src={clock}
                className="img-fluid home-join-img mb-3"
                alt="clock"
              />
              <h4>Get paid on time</h4>
              <p className="join-p">
                No more following up with the client for payments. Gratif
                ensures your payments are always on time.
              </p>
            </div>

            <div className="col-md-4">
              <img
                src={pay}
                className="img-fluid home-join-img mb-3"
                alt="pay"
              />
              <h4>Work at your convenience </h4>
              <p className="join-p">
                Gratif let’s you work at your free time. Want to work only on
                weekends or specific days of a week? You’re welcome.
              </p>
            </div>
          </div>
        </div>
        <div className="home-separator">
          <img
            className="home-separator-image"
            src={separator}
            alt="Home Separator"
          />
          <h1 className="home-separator-h1">How it works</h1>
          <img
            className="home-separator-image"
            src={separator}
            alt="Home Separator"
          />
        </div>

        <div className="d-flex mt-3 flex-direction-column">
          <div className="work-left-side">
            <img src={model} alt="model " className="work-left-side-img" />
          </div>

          <div className="work-right-side flex-column">
            <div className="circle-text-row">
              <div>
                <div className="circle"></div>
                <div className="line"></div>
              </div>
              <h5>Submit your portfolio and preferred services</h5>
            </div>
            <div className="circle-text-row">
              <div>
                <div className="line"></div>
                <div className="circle"></div>
              </div>
              <h5>Complete the screening process</h5>
            </div>
            <div className="line"></div>
            <div className="circle-text-row">
              <div>
                <div className="last-line"></div>
                <div className="circle last-circle"></div>
              </div>
              <h5>
                Welcome onboard and start shooting once your profile is accepted
              </h5>
            </div>
          </div>
          {/* <div className="work-right-side flex-column">
            <div className="circle-text-row">
              <div className="circle"></div>
              <h5>Submit your portfolio and preferred services</h5>
            </div>
            <div className="line"></div>

            <div className="circle-text-row">
              <div className="circle"></div>
              <h5>Complete the screening process</h5>
            </div>
            <div className="line"></div>

            <div className="circle-text-row">
              <div>
                <div className="line-small"></div>
                <div className="circle"></div>
                <div className="line-small"></div>
              </div>
              <h5>
                An online/offline screening process for portfolio verification
              </h5>
            </div>
            <div className="line"></div>

            <div className="circle-text-row">
              <div className="circle last-circle"></div>
              <h5>
                Welcome onboard and start shooting once your profile is accepted
              </h5>
            </div>
          </div> */}
        </div>
        <div className="d-flex mt-5 mb-5 flex-direction-column">
          <div className="faq-container">
            <div>
              <h1 className="faq-title">FAQs</h1>
              <p className="faq-text">Unresolved queries?</p>
              <span>Email us at </span>
              <a href="mailto:care@gratif.in" className="bold-email">
                care@gratif.in
              </a>
            </div>
            <img src={faq} alt="faq" className="faq-image" />
          </div>
          <div className="faq-right-side">
            {faqs.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                expanded={expandedFAQ === index}
                toggle={() => toggleFAQ(index)}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
