import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import CustomRadio from "../../../components/common/CustomRadio/CustomRadio";
import { ONBOARDING_DATA_UPDATE } from "../../../types/dataTypes";
import OnBoardingHeader from "../OnBoardingHeader/OnBoardingHeader";

const SkillDetails = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxFormData = useSelector((state) => state.onboardingData.data) || {};

  const [formData, setFormData] = useState(reduxFormData);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.primaryPhotographyService) {
      toast.error("Please select at least primary service");
      return;
    }
    if (!formData.photographySkillLevel) {
      toast.error("Please select your skill level");
      return;
    }
    if (!formData.photographyExperience) {
      toast.error("Please select your experience");
      return;
    }
    if (!formData.photographyLightSetup) {
      toast.error("Please select your experience with light setup");
      return;
    }
    if (!formData.wirelessLight) {
      toast.error("Please select your experience with wireless lights");
      return;
    }
    dispatch({ type: ONBOARDING_DATA_UPDATE, payload: formData });
    navigate("/gear-details");
  };

  const groupedOptions = [
    {
      label: "Photography",
      options: [
        { label: "Linkedin headshot", value: "linkedinHeadshot" },
        { label: "Passport Photography", value: "passportPhotography" },
        { label: "Maternity", value: "maternity" },
        { label: "Baby Portfolio", value: "babyPortfolio" },
        {
          label: "Matrimonial/Profile shoot",
          value: "matrimonialProfileShoot",
        },
        {
          label: "Passport/Visa Photography",
          value: "passportVisaPhotography",
        },
        { label: "Food Photography", value: "foodPhotography" },
        { label: "Product Photography", value: "productPhotography" },
        { label: "Fashion-Clothing", value: "fashionClothing" },
        { label: "360 virtual tour", value: "360VirtualTour" },
      ],
    },
    {
      label: "Videography",
      options: [
        { label: "Interviews", value: "interviews" },
        { label: "Testimonials", value: "testimonials" },
        { label: "Podcast production", value: "podcastProduction" },
      ],
    },
    {
      label: "Photography & Videography",
      options: [
        { label: "Corporate Events", value: "corporateEvents" },
        { label: "Pre-Wedding", value: "preWedding" },
        { label: "Real estate", value: "realEstate" },
        { label: "Wedding", value: "wedding" },
      ],
    },
  ];

  return (
    <>
      <OnBoardingHeader
        nextLink="/gear-details"
        progressBarValue={40}
        breadcrumbStatus={["Home", "Personal Details"]}
      />
      <div className="container mt-5">
        <h2>Your Skills in a Nutshell</h2>
        <form onSubmit={handleSubmit}>
          <div className="mt-5">
            <div className="mt-4">
              <div className="mt-5">
                <label
                  htmlFor="primaryService"
                  className="form-label fs-5 mb-3 fw-500"
                >
                  Select one primary service that you have laser like focus on*
                </label>
                <Select
                  name="primaryService"
                  options={groupedOptions}
                  formatGroupLabel={(data) => (
                    <div
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                      }}
                    >
                      <span>{data.label}</span>
                    </div>
                  )}
                  className="basic-single-select"
                  classNamePrefix="select"
                  onChange={(selectedOption) => {
                    let secondaryServices =
                      formData.secondaryPhotographyService;
                    if (
                      selectedOption &&
                      secondaryServices.includes(selectedOption.label)
                    ) {
                      secondaryServices = secondaryServices.filter(
                        (service) => service !== selectedOption.label
                      );
                    }
                    setFormData({
                      ...formData,
                      primaryPhotographyService: selectedOption
                        ? selectedOption.label
                        : "",
                      secondaryPhotographyService: secondaryServices,
                    });
                  }}
                  value={
                    formData.primaryPhotographyService
                      ? {
                          label: formData.primaryPhotographyService,
                          value: formData.primaryPhotographyService,
                        }
                      : null
                  }
                />

                <label
                  htmlFor="secondaryServices"
                  className="form-label fs-5 fw-500 mt-4"
                  style={{ marginBottom: "0px" }}
                >
                  Select up to 4 secondary services that you’re interested in
                </label>
                <p>
                  Please ensure you have relevant portfolio to avoid rejection.
                </p>
                <Select
                  isDisabled={!formData.primaryPhotographyService}
                  isMulti
                  name="secondaryServices"
                  options={groupedOptions.map((group) => ({
                    ...group,
                    options: group.options.filter(
                      (option) =>
                        option.label !== formData.primaryPhotographyService &&
                        !formData.secondaryPhotographyService.includes(
                          option.label
                        )
                    ),
                  }))}
                  formatGroupLabel={(data) => (
                    <div
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                      }}
                    >
                      <span>{data.label}</span>
                    </div>
                  )}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onMenuOpen={() => setMenuOpen(true)}
                  onMenuClose={() => setMenuOpen(false)}
                  menuIsOpen={menuOpen}
                  onChange={(selectedOptions) => {
                    if (selectedOptions && selectedOptions.length > 4) {
                      return;
                    }
                    setFormData({
                      ...formData,
                      secondaryPhotographyService: selectedOptions
                        ? selectedOptions.map((option) => option.label)
                        : [],
                    });
                    if (selectedOptions.length === 4) {
                      setMenuOpen(false);
                    } else {
                      setMenuOpen(true);
                    }
                  }}
                  value={formData.secondaryPhotographyService.map(
                    (service) => ({
                      label: service,
                      value: service,
                    })
                  )}
                />
              </div>

              <div className="mt-5">
                <label className="form-label fs-5 mb-3 fw-500">
                  What describes you the best?*
                </label>
                <CustomRadio
                  label="Student/Hobbyist"
                  value="student"
                  name="photographySkillLevel"
                  selected={formData.photographySkillLevel === "student"}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      photographySkillLevel: "student",
                    })
                  }
                  addDefaultMargin={true}
                />
                <CustomRadio
                  label="Professional (Solo)"
                  value="professionalSolo"
                  name="photographySkillLevel"
                  selected={
                    formData.photographySkillLevel === "professionalSolo"
                  }
                  onChange={() =>
                    setFormData({
                      ...formData,
                      photographySkillLevel: "professionalSolo",
                    })
                  }
                  addDefaultMargin={true}
                />
                <CustomRadio
                  label="Professional (With a team)"
                  value="professionalWithATeam"
                  name="photographySkillLevel"
                  selected={
                    formData.photographySkillLevel === "professionalWithATeam"
                  }
                  onChange={() =>
                    setFormData({
                      ...formData,
                      photographySkillLevel: "professionalWithATeam",
                    })
                  }
                  addDefaultMargin={true}
                />
                <CustomRadio
                  label="Agency"
                  value="agency"
                  name="photographySkillLevel"
                  selected={formData.photographySkillLevel === "agency"}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      photographySkillLevel: "agency",
                    })
                  }
                  addDefaultMargin={true}
                />
              </div>

              <div className="mt-5">
                <label className="form-label fs-5 mb-3 fw-500">
                  Select the years of experience you have*
                </label>
                <CustomRadio
                  label="1-2 years"
                  value="1-2"
                  name="photographyExperience"
                  selected={formData.photographyExperience === "1-2"}
                  onChange={() =>
                    setFormData({ ...formData, photographyExperience: "1-2" })
                  }
                  addDefaultMargin={true}
                />
                <CustomRadio
                  label="3-5 years"
                  value="3-5"
                  name="photographyExperience"
                  selected={formData.photographyExperience === "3-5"}
                  onChange={() =>
                    setFormData({ ...formData, photographyExperience: "3-5" })
                  }
                  addDefaultMargin={true}
                />
                <CustomRadio
                  label="6+"
                  value="6+"
                  name="photographyExperience"
                  selected={formData.photographyExperience === "6+"}
                  onChange={() =>
                    setFormData({ ...formData, photographyExperience: "6+" })
                  }
                  addDefaultMargin={true}
                />
              </div>

              <div className="mt-5">
                <label className="form-label fs-5 mb-3 fw-500">
                  Do you have experience using a three point light setup?*
                </label>
                <div className="custom-radio-group">
                  <CustomRadio
                    label="Yes"
                    value="yes"
                    name="photographyLightSetup"
                    selected={formData.photographyLightSetup === "yes"}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        photographyLightSetup: "yes",
                      })
                    }
                  />
                  <CustomRadio
                    label="No"
                    value="no"
                    name="photographyLightSetup"
                    selected={formData.photographyLightSetup === "no"}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        photographyLightSetup: "no",
                      })
                    }
                  />
                  <CustomRadio
                    label="Partially"
                    value="partially"
                    name="photographyLightSetup"
                    selected={formData.photographyLightSetup === "partially"}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        photographyLightSetup: "partially",
                      })
                    }
                  />
                </div>
              </div>

              <div className="mt-5">
                <label className="form-label fs-5 mb-3 fw-500">
                  Do you have experience using wireless lights?*
                </label>
                <div className="custom-radio-group">
                  <CustomRadio
                    label="Yes"
                    value="yes"
                    name="wirelessLight"
                    selected={formData.wirelessLight === "yes"}
                    onChange={() =>
                      setFormData({ ...formData, wirelessLight: "yes" })
                    }
                  />
                  <CustomRadio
                    label="No"
                    value="no"
                    name="wirelessLight"
                    selected={formData.wirelessLight === "no"}
                    onChange={() =>
                      setFormData({ ...formData, wirelessLight: "no" })
                    }
                  />
                </div>
              </div>

              <div className="mt-5">
                <label
                  className="form-label fs-5 fw-500"
                  style={{ marginBottom: "0px" }}
                >
                  Do you have experience setting up backgrounds for
                  food/fashion/headshots?
                </label>
                <p>
                  Answer this If you've selected linkedin headshots/
                  fashion/food photography as one of your expertise services. If
                  not, you can skip this
                </p>
                <div className="custom-radio-group">
                  <CustomRadio
                    label="Yes"
                    value="yes"
                    name="foodFashionHeadshot"
                    selected={formData.foodFashionHeadshot === "yes"}
                    onChange={() =>
                      setFormData({ ...formData, foodFashionHeadshot: "yes" })
                    }
                  />
                  <CustomRadio
                    label="No"
                    value="no"
                    name="foodFashionHeadshot"
                    selected={formData.foodFashionHeadshot === "no"}
                    onChange={() =>
                      setFormData({ ...formData, foodFashionHeadshot: "no" })
                    }
                  />
                </div>
              </div>

              <div className="mt-5 mb-5">
                <label
                  className="form-label fs-5 fw-500"
                  style={{ marginBottom: "0px" }}
                >
                  Do you have experience handling just born babies for baby
                  portfolio shoots?
                </label>
                <p>
                  Do you have experience handling just born babies for baby
                  portfolio shoots? Answer this If you've selected baby
                  portfolio as one of your expertise services. If not, you can
                  skip this
                </p>
                <div className="custom-radio-group">
                  <CustomRadio
                    label="Yes"
                    value="yes"
                    name="themeProps"
                    selected={formData.themeProps === "yes"}
                    onChange={() =>
                      setFormData({ ...formData, themeProps: "yes" })
                    }
                  />
                  <CustomRadio
                    label="No"
                    value="no"
                    name="themeProps"
                    selected={formData.themeProps === "no"}
                    onChange={() =>
                      setFormData({ ...formData, themeProps: "no" })
                    }
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="orange-button-onboard-mobile mt-4">
              Next
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default SkillDetails;
