import React, { Component } from "react";
import { Route, Routes } from "react-router";
import AboutUs from "../pages/AboutUs/AboutUs";
import AccountDeletionPage from "../pages/AccountDeletionPage/AccountDeletionPage";
import ContactUs from "../pages/ContactUs/ContactUs";
import HomePage from "../pages/HomePage/HomePage";
import JoinPage from "../pages/JoinPage/JoinPage";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import GearDetails from "../pages/OnBoarding/GearDetails/GearDetails";
import OnBoardingSuccess from "../pages/OnBoarding/OnBoardingSuccess/OnBoardingSuccess";
import PersonalDetails from "../pages/OnBoarding/PersonalDetails/PersonalDetails";
import PortfolioUpload from "../pages/OnBoarding/Portfolio/PortfolioUpload";
import SkillDetails from "../pages/OnBoarding/SkillDetails/SkillDetails";
import TimingPreference from "../pages/OnBoarding/TimingPreference/TimingPreference";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import RefundPage from "../pages/RefundPage/RefundPage";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";

class AppRoute extends Component {
  render() {
    return (
      <>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/join" element={<JoinPage />} />
          <Route path="/personal-details" element={<PersonalDetails />} />
          <Route path="/skill-details" element={<SkillDetails />} />
          <Route path="/gear-details" element={<GearDetails />} />
          <Route path="/timing-preference" element={<TimingPreference />} />
          <Route path="/portfolio-upload" element={<PortfolioUpload />} />
          <Route path="/onboarding-success" element={<OnBoardingSuccess />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/cancellation" element={<RefundPage />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/delete-account" element={<AccountDeletionPage />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </>
    );
  }
}

export default AppRoute;
