import { toast } from "react-toastify";
import AppURL from "../api/AppUrls";

class ApiClient {
  constructor() {
    if (!ApiClient.instance) {
      this.backendAddress = AppURL.baseUrl;
      ApiClient.instance = this;
    }

    return ApiClient.instance;
  }

  async request(
    endpoint,
    method = "GET",
    body = null,
    isFormData = false,
    headers = {}
  ) {
    try {
      const token = localStorage.getItem("token");
      headers["Authorization"] = `Bearer ${token}`;

      const config = {
        method,
        headers: {
          ...headers,
        },
      };

      if (isFormData) {
        config.body = body;
      } else {
        config.headers["Content-Type"] = "application/json";
        if (body) {
          config.body = JSON.stringify(body);
        }
      }

      const response = await fetch(`${this.backendAddress}${endpoint}`, config);
      console.log(response.data);
      if (response.ok) {
        const data = await response.json();
        return data.data ? data.data : data;
      } else {
        const errorData = await response.json();
        if (errorData.message) {
          toast.error(errorData.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while creating the banner");
      }
    }
  }
}

const apiClient = new ApiClient();
Object.freeze(apiClient);

export default apiClient;
