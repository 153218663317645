import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/gratif_logo.png";
import "./Header.css";

const Header = () => {
  return (
    <header className="container">
      <div className="header-logo">
        <Link to="/">
          <img className="gratif-logo-image" src={logo} alt="Gratif Logo" />
        </Link>
      </div>
      {/* <div className="header-right">
        <h1 className="header-right-text">Ready to snap the world?</h1>
        <Link to="/join" className="onboard header-button">
          Join Gratif Today
        </Link>
      </div> */}
    </header>
  );
};

export default Header;
