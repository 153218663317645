import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomRadio from "../../../components/common/CustomRadio/CustomRadio";
import { ONBOARDING_DATA_UPDATE } from "../../../types/dataTypes";
import OnBoardingHeader from "../OnBoardingHeader/OnBoardingHeader";

const TimingPreference = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxFormData = useSelector((state) => state.onboardingData.data) || {};

  const [formData, setFormData] = useState(reduxFormData);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: ONBOARDING_DATA_UPDATE, payload: formData });
    navigate("/portfolio-upload");
  };

  return (
    <>
      <OnBoardingHeader
        nextLink="/portfolio-upload"
        progressBarValue={80}
        breadcrumbStatus={[
          "Home",
          "Personal Details",
          "Skill Details",
          "Gear Details",
        ]}
      />
      <form onSubmit={handleSubmit}>
        <div className="container mt-5">
          <h2>Your preferred timings</h2>

          <div className="mt-5">
            <label
              className="form-label fs-5 fw-500"
              style={{ marginBottom: "0px" }}
            >
              What are your preferred days of work?
            </label>
            <p>
              First slot of the day is 8AM and the last slot of the day is 7PM
            </p>
            <div className="custom-radio-group">
              <CustomRadio
                label="All days"
                value="allDays"
                selected={formData.preferredDays === "allDays"}
                name="preferredDays"
                required
                onChange={() =>
                  setFormData({
                    ...formData,
                    preferredDays: "allDays",
                  })
                }
              />
              <CustomRadio
                label="Weekdays"
                value="weekDays"
                selected={formData.preferredDays === "weekDays"}
                required
                name="preferredDays"
                onChange={() =>
                  setFormData({
                    ...formData,
                    preferredDays: "weekDays",
                  })
                }
              />
              <CustomRadio
                label="Weekends"
                value="weekends"
                selected={formData.preferredDays === "weekends"}
                required
                name="preferredDays"
                onChange={() =>
                  setFormData({
                    ...formData,
                    preferredDays: "weekends",
                  })
                }
              />
            </div>
          </div>

          <div className="mt-4">
            <label className="form-label fs-5 mb-3 fw-500">
              Are you available for flexible time slots in case of weddings?
            </label>
            <div className="custom-radio-group">
              <CustomRadio
                label="Yes"
                value="yes"
                selected={formData.flexibleTime === "yes"}
                required
                name="flexibleTime"
                onChange={() =>
                  setFormData({
                    ...formData,
                    flexibleTime: "yes",
                  })
                }
              />
              <CustomRadio
                label="No"
                value="no"
                selected={formData.flexibleTime === "no"}
                required
                name="flexibleTime"
                onChange={() =>
                  setFormData({
                    ...formData,
                    flexibleTime: "no",
                  })
                }
              />
            </div>
          </div>

          <button type="submit" className="orange-button-onboard-mobile mt-4">
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default TimingPreference;
