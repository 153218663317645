import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { addonBoardingData } from "../../../actions/onboardingActions";
import uploadIcon from "../../../assets/images/upload_icon.png";
import {
  ONBOARDING_DATA_UPDATE,
  ONBOARDING_SUCCESS_RESET,
} from "../../../types/dataTypes";
import OnBoardingHeader from "../OnBoardingHeader/OnBoardingHeader";
import "./Portfolio.css";

const PortfolioUpload = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.onboardingData.data);
  const reduxFormData = useMemo(() => data || {}, [data]);
  const [localPortfolioImages, setLocalPortfolioImages] = useState(
    reduxFormData.localPortfolioImages || []
  );
  const [imageFiles, setImageFiles] = useState(
    reduxFormData.localPortfolioImages || []
  );
  const [formData, setFormData] = useState(reduxFormData);
  const [videoLinks, setVideoLinks] = useState(
    reduxFormData.portfolioVideos && reduxFormData.portfolioVideos.length > 0
      ? reduxFormData.portfolioVideos
      : [""]
  );
  const loading = useSelector((state) => state.onboardingData.loading);
  const success = useSelector((state) => state.onboardingData.success);

  useEffect(() => {
    if (
      JSON.stringify(reduxFormData.localPortfolioImages) !==
      JSON.stringify(localPortfolioImages)
    ) {
      dispatch({
        type: ONBOARDING_DATA_UPDATE,
        payload: {
          ...reduxFormData,
          localPortfolioImages: localPortfolioImages,
        },
      });
    }
  }, [localPortfolioImages, dispatch, reduxFormData]);

  const handleUpload = async (e) => {
    e.preventDefault();

    const actualFormData = new FormData();
    for (let key in formData) {
      if (formData[key] === null) {
        continue;
      }
      if (Array.isArray(formData[key])) {
        let jsonString = JSON.stringify(formData[key]);
        actualFormData.append(key, jsonString);
      } else {
        actualFormData.append(key, formData[key]);
      }
    }

    if (formData.profileImage) {
      const response = await fetch(formData.profileImage);
      const blob = await response.blob();
      actualFormData.append("profileImage", blob);
    }

    for (const file of imageFiles) {
      if (typeof file === "string" && file.startsWith("blob:")) {
        const response = await fetch(file);
        const blob = await response.blob();
        actualFormData.append("portfolioImages", blob);
      } else {
        actualFormData.append("portfolioImages", file);
      }
    }
    dispatch(addonBoardingData(actualFormData));
  };

  const handleFileChange = (fileList) => {
    const files = Array.from(fileList);
    const maxFileSize = 3 * 1024 * 1024; // 3MB in bytes

    const validFiles = [];
    const fileUrls = [];

    for (let file of files) {
      if (file.size > maxFileSize) {
        toast.error("Each image must be no more than 3MB.");
        return;
      }
      validFiles.push(file);
      fileUrls.push(URL.createObjectURL(file));
    }

    if (localPortfolioImages.length + fileUrls.length > 25) {
      toast.error("You can only upload a maximum of 25 images.");
      return;
    }

    setLocalPortfolioImages((prevImages) => [...prevImages, ...fileUrls]);
    setImageFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const removeImage = (url) => {
    const index = localPortfolioImages.indexOf(url);
    setLocalPortfolioImages((prevImages) =>
      prevImages.filter((image) => image !== url)
    );
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer) {
      handleFileChange(e.dataTransfer.files);
    }
  };

  const handleAddMore = () => {
    if (videoLinks.length < 15) {
      setVideoLinks([...videoLinks, ""]);
    } else {
      toast.error("You can only add a maximum of 15 video links.");
    }
  };

  const handleRemove = (index) => {
    const updatedLinks = [...videoLinks];
    updatedLinks.splice(index, 1);
    setVideoLinks(updatedLinks);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleVideoInputChange = (index, value) => {
    const updatedLinks = [...videoLinks];
    updatedLinks[index] = value;
    setVideoLinks(updatedLinks);

    // Update formData
    setFormData({
      ...formData,
      portfolioVideos: updatedLinks.filter((link) => link !== ""),
    });
  };

  useEffect(() => {
    dispatch({ type: ONBOARDING_DATA_UPDATE, payload: formData });
  }, [formData, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!localPortfolioImages.length) {
      toast.error("Please upload at least one image.");
      return;
    }
    dispatch({
      type: ONBOARDING_DATA_UPDATE,
      payload: { ...reduxFormData, localPortfolioImages: localPortfolioImages },
    });
  };

  useEffect(() => {
    if (success) {
      setLocalPortfolioImages([]);
      setImageFiles([]);
      dispatch({ type: ONBOARDING_SUCCESS_RESET });
    }
  }, [success, dispatch]);

  return (
    <>
      <OnBoardingHeader
        nextLink="/submit"
        progressBarValue={100}
        breadcrumbStatus={[
          "Home",
          "Personal Details",
          "Skill Details",
          "Gear Details",
          "Timing Preference",
          "Portfolio",
        ]}
      />
      <form onSubmit={handleSubmit}>
        <div className="container mt-5 mb-5">
          <h4>
            Share your finest work and let your photography speak for itself
          </h4>
          <p>
            To avoid rejection, please ensure you are submitting images that are
            relevant to the services selected at the beginning of this form
          </p>
          <h5>Upload up to 25 web optimised/compressed images</h5>
          <div className="mb-3 mt-5">
            <label htmlFor="instagramProfileUrl" className="form-label  fs-5">
              If your business has an Instagram page, please add the link
            </label>
            <input
              type="text"
              className="form-control py-3"
              id="instagramProfileUrl"
              placeholder="Add instagram profile link"
              onChange={handleInputChange}
              value={formData.instagramProfileUrl || ""}
              required
            />
          </div>
          <div className="mb-3 mt-4">
            <label htmlFor="websiteUrl" className="form-label  fs-5">
              If your company has a website, please provide the link below
            </label>
            <input
              type="text"
              className="form-control py-3"
              id="websiteUrl"
              placeholder="Add website  link "
              onChange={handleInputChange}
              value={formData.websiteUrl || ""}
              required
            />
          </div>
          <div
            className="upload-container"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
            <p className="upload-text">
              Drag and drop or
              <label htmlFor="fileInput" className="browse-label">
                Browse
              </label>
              file.
            </p>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              accept=".png, .jpg, .jpeg"
              multiple
              onChange={(e) => handleFileChange(e.target.files)}
            />
            <p>Supported formats: png, jpg, jpeg</p>
            <p>Only image support up to 3mb</p>
          </div>
          <div>
            {localPortfolioImages.map((url, index) => (
              <div key={index} className="image-container">
                <img src={url} alt="Uploaded" className="uploaded-image" />
                <button
                  onClick={() => removeImage(url)}
                  className="remove-button"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          <div className="divider mb-5 mt-5"></div>
          <h4 className="mb-4">Enter up to 15 video links</h4>
          {videoLinks.map((link, index) => (
            <div className="mb-3" key={index}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control py-3"
                  required
                  placeholder="Enter youtube, vimeo, etc link"
                  value={link}
                  onChange={(e) =>
                    handleVideoInputChange(index, e.target.value)
                  }
                />
              </div>
              {videoLinks.length > 1 && (
                <h2
                  style={{
                    color: "var(--orange-color)",
                    fontSize: "18px",
                    cursor: "pointer",
                    textAlign: "end",
                    marginTop: "10px",
                  }}
                  onClick={() => handleRemove(index)}
                >
                  Remove
                </h2>
              )}
            </div>
          ))}
          <h2 className="mt-3 add-more" onClick={handleAddMore}>
            <i className="bi bi-plus">ADD MORE</i>
          </h2>
          <button
            type="button"
            className={`orange-button-onboard-mobile mt-4 ${
              loading ? "loading" : ""
            } ${loading ? "disabled" : ""}`}
            onClick={!loading ? handleUpload : null}
            disabled={loading}
            style={{ width: loading ? "150px" : "auto" }}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
      <ToastContainer />
    </>
  );
};

export default PortfolioUpload;
