import React from "react";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./OnBoardingHeader.css";

const BreadcrumbItem = ({ text, isActive, link }) => {
  const circleClass = isActive
    ? "breadcrumb-active-circle"
    : "breadcrumb-inactive-circle";
  const textClass = isActive ? "active-text" : "inactive-text";

  return (
    <div className="breadcrumb-item">
      <div
        className={
          text === "Home" ? "home-icon" : `breadcrumb-circle ${circleClass}`
        }
      >
        {text === "Home" && (
          <Link to="/" className="breadcrumb-home-icon">
            <i className="bi bi-house-door-fill"></i>
          </Link>
        )}
      </div>
      {text !== "Home" && (
        <div className={textClass}>
          {isActive ? <Link to={link}>{text}</Link> : text}
        </div>
      )}
    </div>
  );
};

const OnBoardingHeader = ({ nextLink, progressBarValue, breadcrumbStatus }) => {
  const breadcrumbData = [
    { text: "Home", link: "/" },
    { text: "Personal Details", link: "/personal-details" },
    { text: "Skill Details", link: "/skill-details" },
    { text: "Gear Details", link: "/gear-details" },
    { text: "Timing Preference", link: "/timing-preference" },
    { text: "Portfolio", link: "/portfolio-image-upload" },
  ];

  return (
    <>
      <div className="sticky">
        <div className="onboarding-header-container">
          <div className="onboarding-header desktop-onboarding-container">
            {breadcrumbData.map(({ text, link }, index) => (
              <BreadcrumbItem
                key={index}
                text={text}
                isActive={breadcrumbStatus.includes(text)}
                link={link}
              />
            ))}
          </div>
          <div className="mobile-onboarding-container">
            <div className="onboarding-header mb-2">
              {breadcrumbData.slice(0, 3).map(({ text, link }, index) => (
                <BreadcrumbItem
                  key={index}
                  text={text}
                  isActive={breadcrumbStatus.includes(text)}
                  link={link}
                />
              ))}
            </div>
            <div className="onboarding-header-mobile">
              {breadcrumbData.slice(3, 6).map(({ text, link }, index) => (
                <BreadcrumbItem
                  key={index}
                  text={text}
                  isActive={breadcrumbStatus.includes(text)}
                  link={link}
                />
              ))}
            </div>
          </div>
          {/* {nextLink !== "submit" ? (
          <Link to={nextLink} className="orange-button-onboard">
            Next
          </Link>
        ) : (
          <h4 className="orange-button-onboard">Submit</h4>
        )} */}
        </div>
        <ProgressBar
          style={{ height: "8px", borderRadius: "0" }}
          variant="black"
          now={progressBarValue}
        />
      </div>
    </>
  );
};

export default OnBoardingHeader;
