class AppURL {
  static baseUrl = "https://app.gratif.in/";
  // static baseUrl = "http://localhost:4455/";

  // photographer url
  static photographerUrl = "photographer";
  static contactUsUrl = "user/contact-us";
}

export default AppURL;
