import {
  ONBOARDING_DATA_FAILURE,
  ONBOARDING_DATA_REQUEST,
  ONBOARDING_DATA_SUCCESS,
  ONBOARDING_DATA_UPDATE,
  ONBOARDING_SUCCESS_RESET,
} from "../types/dataTypes";

const initialState = {
  loading: false,
  data: {
    profileImage: "",
    name: "",
    email: "",
    countryCode: "+91",
    mobileNumber: "",
    address: "",
    state: "",
    city: "",
    businessName: "",
    gstNumber: "",
    languages: "",
    primaryPhotographyService: "",
    secondaryPhotographyService: [],
    photographySkillLevel: null,
    photographyExperience: null,
    photographyLightSetup: null,
    wirelessLight: null,
    foodFashionHeadshot: null,
    themeProps: null,
    cameraBodyAndType: [{ id: 1, cameraBody: "", bodyType: "" }],
    cameraSupport: [],
    cameraLens: "",
    mics: "",
    lights: "",
    highSpeedSync: false,
    extraBattery: false,
    photoEditingTools: [],
    videoEditingTools: [],
    preferredDays: "",
    flexibleTime: false,
    localPortfolioImages: [],
    portfolioVideos: [],
    instagramProfileUrl: "",
    websiteUrl: "",
    tempData: {
      photoEditingTool: "",
      cameraSupport: "",
    },
  },
  error: null,
  success: false,
};

const onboardingReducers = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_DATA_UPDATE:
      return { ...state, data: action.payload };
    case ONBOARDING_DATA_REQUEST:
      return { ...state, loading: true };
    case ONBOARDING_DATA_SUCCESS:
      return { ...initialState, success: true };
    case ONBOARDING_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ONBOARDING_SUCCESS_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

export default onboardingReducers;
