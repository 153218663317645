import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container">
      <h1>Terms and Conditions</h1>
      <p>Last updated as of December 29, 2023.</p>

      <p>
        Gratif, along with its subsidiary Mountainscale Private Limited
        (collectively referred to as "Gratif," "we," "us," or "our"), offers an
        online booking, payment, and matching marketplace connecting
        photographers, video production houses, and users in need of on-demand
        photography and video production services (collectively known as the
        "Services"). These Services are provided through the website at{" "}
        <a href="https://www.gratif.in">www.Gratif.in</a> (the "Site") and
        mobile applications, subject to the following Terms of Service (referred
        to as the "Terms of Service"). We reserve the right, at our discretion,
        to change or modify parts of these Terms of Service at any time. In such
        cases, we will post the changes on this page and specify the date of the
        last revision at the top of the page. Notification of changes will also
        be communicated through the Services user interface, email notification,
        or other reasonable means. These changes will become effective no
        earlier than fourteen (14) days after being posted, except for changes
        addressing new functions or legal requirements, which will be effective
        immediately. Your continued use of the Service after the effective date
        constitutes acceptance of the updated Terms of Service.
      </p>

      <p>
        PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN
        AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION ABOUT YOUR LEGAL
        RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES
        THAT YOU SUBMIT CLAIMS TO BINDING AND FINAL ARBITRATION, AND YOU WILL
        ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST GRATIF ON AN INDIVIDUAL
        BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
        ACTION. YOU WILL ONLY BE PERMITTED TO SEEK RELIEF ON AN INDIVIDUAL
        BASIS.
      </p>

      <p>
        Additionally, while using certain services, you will be subject to
        additional terms applicable to those services, posted on the Service,
        including the Privacy Policy found at{" "}
        <a href="https://www.gratif.in/privacy-policy">
          www.Gratif.in/privacy-policy
        </a>
        . All such terms are incorporated by reference into these Terms of
        Service.
      </p>

      <p>
        THE SITE INCLUDES AN ONLINE MARKETPLACE THROUGH WHICH PHOTOGRAPHERS
        (DEFINED BELOW) MAY OFFER PHOTOGRAPHY SERVICES (DEFINED BELOW), AND
        SUBJECTS (DEFINED BELOW) MAY BOOK SUCH PHOTOGRAPHY SERVICES. YOU
        ACKNOWLEDGE AND AGREE THAT: (1) GRATIF IS NOT A PARTY TO ANY AGREEMENTS
        BETWEEN PHOTOGRAPHERS AND SUBJECTS, (2) GRATIF PROVIDES MATCHING AND
        PAYMENT PROCESSING SERVICES ONLY, (3) SUBJECTS CONTRACT FOR PHOTOGRAPHY
        SERVICES DIRECTLY WITH PHOTOGRAPHERS, (4) GRATIF IS NOT A BROKER OR
        INSURER, AND (5) GRATIF HAS NO CONTROL OVER THE CONDUCT OF USERS OR THE
        QUALITY OF PHOTOGRAPHY SERVICES, DISCLAIMING ALL LIABILITY IN THIS
        REGARD. PLEASE READ THE TERMS CAREFULLY, AS DIFFERENT SECTIONS APPLY TO
        PHOTOGRAPHERS AND SUBJECTS.
      </p>
      <p>
        <strong>Key Terms</strong>
      </p>

      <p>
        <em>"User," "you," or "your"</em> refers to a person, organization, or
        entity using the Services, including Subjects and Photographers.
        <em>"Subject"</em> is a person who completes Gratif's account
        registration process to book a Photographer.
        <em>"Photographer"</em> is a photographer providing the sought-after
        photography services and accepted by Gratif on the Site.
        <em>"Photography Services"</em> means photography-related services
        provided by Photographers.
      </p>

      <p>
        <strong>Access and Use of the Service</strong>
      </p>

      <p>
        <strong>Services</strong>
      </p>

      <p>
        As mentioned, Gratif's Services provide a marketplace and related
        technology for Subjects and Photographers to match and book Photography
        Services. Gratif does not offer any photography-related services but is
        limited to (i) facilitating the availability of the Site and (ii)
        serving as the limited agent of each Photographer to accept payments
        from a Subject. Risks associated with interactions with other Users are
        your responsibility, and Gratif does not control User behavior or
        guarantee the authenticity, quality, safety, or legality of Photography
        Services.
      </p>

      <p>
        <strong>Your Registration Obligations</strong>
      </p>

      <p>
        You may need to register with Gratif to access certain features of the
        Service. By registering, you agree to provide accurate and complete
        information about yourself and maintain this information. Registration
        data and other information are subject to our Privacy Policy. If you are
        under 13, you are not authorized to use the Service. If you are under
        18, you may use the Service only with parental or guardian approval.
      </p>

      <p>
        <strong>User Account, Password, and Security</strong>
      </p>

      <p>
        Maintaining password and account confidentiality is your responsibility.
        You must promptly notify Gratif of any unauthorized use or security
        breaches. Gratif is not liable for losses due to failure to comply with
        security measures.
      </p>

      <p>
        <strong>Modifications to Service</strong>
      </p>

      <p>
        Gratif reserves the right to modify or discontinue the Service, with or
        without notice. Gratif is not liable for any modification, suspension,
        or discontinuance of the Service.
      </p>

      <p>
        <strong>General Practices Regarding Use and Storage</strong>
      </p>

      <p>
        Gratif may establish general practices and limits for Service use. This
        includes data retention periods and storage space on Company servers.
        Gratif is not responsible for data deletion or failure to store content
        and may terminate inactive accounts. Gratif may change these practices
        and limits at its discretion.
      </p>

      <p>
        <strong>Mobile Services</strong>
      </p>

      <p>
        The Service includes Mobile Services, subject to your wireless carrier's
        charges and restrictions. By using Mobile Services, you agree to receive
        communications from Gratif via SMS, MMS, or other electronic means. You
        also agree that information about your Mobile Services usage may be
        communicated to Gratif.
      </p>
      <p>
        <strong>Matching</strong>
      </p>

      <p>
        Gratif aims to match Subjects with suitable Photographers based on
        provided information. Subjects may choose from presented Photographer
        options, and it is the Subject's responsibility to make the final
        selection. Gratif reserves the right to change the Photographer
        providing Photography Services for a Booking at its discretion, with
        notification to the Subject.
      </p>

      <p>
        <strong>Photographer Terms</strong>
      </p>

      <p>
        <strong>No Off-Platform Activity</strong>
      </p>

      <p>
        As a Photographer, you may not encourage Subjects to receive services
        similar to the Photographer Services off the Site. You acknowledge and
        agree that a significant portion of the compensation Gratif receives for
        providing the Site is collected through the Gratif Fee described in
        Payment Terms. Gratif only receives this Fee when a Subject and a
        Photographer pay and receive payment through the Site. Therefore, for 12
        months from the time you identify or are identified by any party through
        the Site (the “Non-Circumvention Period”), you must use the Site as your
        exclusive method to request, make, and receive all payments for work
        directly or indirectly with that party or arising out of your
        relationship with that party.
      </p>

      <p>
        If you breach this obligation concerning each Subject-Photographer
        relationship during the Non-Circumvention Period, you agree that one or
        both of the Subject or Photographer shall pay Gratif for each such
        off-platform relationship by paying an “Off-Platform Activity Penalty”
        totaling INR 3.0 lakhs per off-platform relationship.
      </p>

      <p>
        To pay the Off-Platform Activity Penalty, you must request instructions
        by contacting Gratif using the contact details below. Except if you pay
        the Off-Platform Activity Penalty, you agree not to circumvent the
        Payment Terms offered by the Site.
      </p>

      <p>
        <strong>Background Checks</strong>
      </p>

      <p>
        Before a Photographer may perform Services through Gratif, the
        Photographer is required to consent to a background check to screen for
        criminal history that may present a safety risk to Gratif or Subjects.
        Photographer accounts will not be eligible for activation if the
        background check reveals any criminal or other history that may present
        a safety risk to Service Providers, including, without limitation,
        violent crimes, felonies, drug-related offenses, sexual offenses, theft,
        or property damage offenses. Gratif reserves the right to disqualify or
        refuse service to any Photographer for any reason, unless prohibited by
        law.
      </p>
      <p>
        By agreeing to these Terms of Service as a Photographer, I hereby
        consent to the preparation of background reports regarding me by a third
        party (e.g., Checkr) and the release of such reports to Gratif and its
        designated representatives, to assist Gratif in determining my
        eligibility to use the Services and at any time to assist Gratif in
        evaluating my continued compliance with these Terms of Service. To this
        end, I hereby authorize, without reservation, any state or federal law
        enforcement agency or court, educational institution, motor vehicle
        record agency, credit bureau, or other information service bureau or
        data repository, or employer to furnish any and all information
        regarding me to Gratif and/or its agents, and authorize Gratif’s agents
        to provide such information to Gratif.
      </p>

      <p>
        The report may include, but is not limited to, criminal and other public
        records and history; public court records; motor vehicle and driving
        records; professional disciplinary actions; drug/alcohol test results;
        and address history, subject to any limitations imposed by applicable
        federal and state law. This information may be obtained from public
        record and private sources, including credit bureaus, government
        agencies, and judicial records, and other sources.
      </p>

      <p>
        The report will be obtained in compliance with the Fair Credit Reporting
        Act. A summary of rights under the Fair Credit Reporting Act may be
        found on the Consumer Financial Protection Bureau website at{" "}
        <a
          href="http://files.consumerfinance.gov/f/201504_cfpb_summary_your-rights-under-fcra.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://files.consumerfinance.gov/f/201504_cfpb_summary_your-rights-under-fcra.pdf
        </a>
      </p>

      <p>
        <strong>Confidentiality</strong>
      </p>

      <p>
        Photographer agrees that certain confidential information (including,
        without limitation, photography guides, information packages, computer
        programs, technical drawings, algorithms, know-how, formulas, processes,
        ideas, inventions (whether patentable or not), schematics, and other
        technical, business, financial, customer, and product development plans,
        forecasts, strategies, and information) that Photographer learns or
        obtains in connection with the Services constitute “Proprietary
        Information.” Photographer will hold in confidence and not disclose or,
        except in performing the Services, use any Proprietary Information.
        Photographer will prevent unauthorized access to or disclosure of the
        Proprietary Information using at least the same degree of care as
        Photographer uses to protect its own most sensitive proprietary
        information (which will not be less than a reasonable degree of care).
        However, Photographer shall not be obligated under this paragraph
        concerning information Photographer can document is or becomes readily
        publicly available without restriction through no fault of Developer.
        Upon termination and as otherwise requested by Gratif, Photographer will
        promptly return to Gratif all items and copies containing or embodying
        Proprietary Information.
      </p>

      <p>
        <strong>Other Photographer Obligations</strong>
      </p>

      <p>
        As a Photographer, you must provide Gratif with any information
        requested to match you to a Subject, such as information on your
        background, experience and skills, sample photos taken by you, contact
        details, and your headshot. You must also provide Gratif with
        up-to-date, complete, and accurate information regarding the days and
        times you are available to complete Bookings and must ensure that this
        information remains accurate and current at all times. Once you are
        matched with a Subject, you have the right to turn down the job, but
        Gratif has the sole discretion to determine if such refusal will harm
        your ability to be matched in the future. Gratif may also request that
        you submit a portfolio of previous works. Gratif has sole discretion as
        to which photographers are accepted into the marketplace, and we reserve
        the right to reject any potential Photographer and remove any
        Photographer from the marketplace for any reason. Gratif may, but is not
        required to, conduct background checks on Photographers.
      </p>
      <p>
        EACH PHOTOGRAPHER IS SOLELY RESPONSIBLE FOR OBTAINING ALL PERMITS,
        LICENSES, AND OTHER PERMISSIONS REQUIRED TO OFFER OR PROVIDE ANY
        PHOTOGRAPHY SERVICES OR OTHER PHOTOGRAPHER-PROVIDED SERVICES, AND GRATIF
        ASSUMES NO RESPONSIBILITY FOR A PHOTOGRAPHER'S FAILURE TO OBTAIN SUCH
        PERMITS, LICENSES, OR PERMISSIONS OR OTHERWISE COMPLY WITH ANY
        APPLICABLE LAWS, RULES, OR REGULATIONS.
      </p>

      <p>
        Nothing in this Agreement will in any way be construed to constitute
        Photographer as an agent, employee, or representative of Gratif, but
        Photographer will perform the Services hereunder as an independent
        contractor. Photographer acknowledges and agrees that Photographer is
        obligated to report as income all compensation received by Photographer
        pursuant to this Agreement, and Photographer agrees to and acknowledges
        the obligation to pay all self-employment and other taxes thereon and
        that he will not be eligible for any employee benefits (nor does he
        desire any of them) and expressly waives any entitlement to such
        benefits. Photographer acknowledges and agrees that he will use his own
        discretion in performing the tasks assigned, within the scope of work
        specified by the Company.
      </p>

      <p>
        You understand and agree that Gratif is neither an insurer nor a
        contracting agent or employer for you as a Photographer. If a Subject
        purchases any of your Photography Services, any agreement you enter into
        with such Subject is between you and the Subject, and Gratif is not a
        party thereto. Notwithstanding the foregoing, Gratif serves as your
        limited authorized agent for the purpose of accepting payments from
        Subjects on your behalf, and Gratif is responsible for transmitting your
        portion of such payments to you. You acknowledge and agree that, as a
        Photographer, you are responsible for your own acts and omissions. Each
        Photographer understands the fact that Gratif does not offer any medical
        coverage for bodily harm or injury that may result from providing the
        Photography Services. Gratif will not reimburse for any medical bills,
        transportation, or loss of wages as a result of the injury or for any
        accommodations. Gratif will have the sole discretion to issue a monetary
        penalty to Photographer if Photographer cancels a shoot with a Subject
        without reasonable notice, does not show up for a scheduled shoot with a
        Subject, or otherwise fails to perform obligations through no fault of
        the Photographer.
      </p>
      <p>
        <strong>Payment Terms</strong>
      </p>

      <p>
        <strong>General</strong>
      </p>

      <p>
        Each Subject agrees to pay all applicable fees for Photography Services
        and fees for any digital downloads or prints purchased either through a
        package or after the completion of the Photography Services ("Purchased
        Prints") as set forth on the Site (collectively, "Photographer Fees").
        All Photographer Fees are payable in the currency specified on the Site
        at the time of Booking. If a Subject requests amended Photography
        Services, including but not limited to changes to the time, location, or
        type of Photography Services provided ("Variation"), a change fee may be
        charged. All change fees are payable before any Variations will be
        accepted and performed.
      </p>

      <p>
        Gratif may also charge fees to Subjects for the use of the Services (the
        "Service Fee"). The Service Fee is equivalent to 5% of the Photographer
        Fees. Gratif reserves the right to change the Service Fee at any time
        and will provide Subjects with notice of any change to the Service Fee
        before such change takes effect by updating the Terms of Service.
        Changes to the Service Fee will not affect any Booking made prior to the
        effective date of the fee change.
      </p>

      <p>
        You shall be responsible for all taxes associated with the Services,
        based on the Company's net income. Except as otherwise expressly set
        forth herein or as expressly approved by Gratif in writing in its sole
        discretion, all payments made are final and non-refundable, and the
        Subject shall not have the right to cancel its purchase for any reason.
        If you make a payment by credit card or another payment instrument, you
        represent and warrant to the Company that such information is true and
        that you are authorized to use such a payment instrument. You will
        promptly update your account information with any changes (for example,
        a change in your billing address or credit card expiration date) that
        may occur. You agree to pay the Company the amount specified on the Site
        for Photographer Fees and in accordance with the terms of such fees,
        purchases, and this Terms of Service.
      </p>

      <p>
        <strong>Photographer Payment</strong>
      </p>

      <p>
        Gratif will facilitate bi-monthly payments to a Photographer's bank
        account, representing the total Photographer Fees accrued for the
        Photography Services provided to a Subject by such Photographer during
        the preceding payment period, less the fee payable to Gratif for
        referring the Subject(s) to such Photographer as set forth in terms
        provided to Photographers at the time of their onboarding ("Gratif
        Fee"). Gratif has the sole discretion to act on behalf of the Subject
        and not pay Photographer Fees if the Subject reports that the
        Photography Services were not provided or adequately completed. This may
        include, but is not limited to, circumstances where a Photographer did
        not arrive for a Booking or photos were of insufficient quality for use.
        Gratif will independently review such cases, seeking input from the
        Subject and/or the Photographer at its sole discretion, and may decide
        to issue a refund to the Subject.
      </p>
      <p>
        <strong>Photographer Payment</strong>
      </p>

      <p>
        Gratif will facilitate bi-monthly payments to a Photographer's bank
        account, representing the total Photographer Fees accrued for the
        Photography Services provided to a Subject by such Photographer during
        the preceding payment period, less the fee payable to Gratif for
        referring the Subject(s) to such Photographer as set forth in terms
        provided to Photographers at the time of their onboarding ("Gratif
        Fee"). Gratif has the sole discretion to act on behalf of the Subject
        and not pay Photographer Fees if the Subject reports that the
        Photography Services were not provided or adequately completed. This may
        include, but is not limited to, circumstances where a Photographer did
        not arrive for a Booking or photos were of insufficient quality for use.
        Gratif will independently review such cases, seeking input from the
        Subject and/or the Photographer at its sole discretion, and may decide
        to issue a refund to the Subject.
      </p>

      <p>
        <strong>Limited Payment Collections Agent</strong>
      </p>

      <p>
        Each Photographer appoints Gratif as the Photographer's limited payment
        collection agent solely for the purpose of accepting the Photographer
        Fees from the Subject. Each User agrees that payment of Photographer
        Fees in respect of a Photographer by a Subject to Gratif, as that
        Photographer's limited payment collection agent, shall be considered the
        same as a payment made directly by that Subject to the relevant
        Photographer, and the Photographer will provide the relevant
        Photographer Services to the Subject, as outlined on the Site, as if the
        Photographer had received payment directly. Gratif, as a limited payment
        collection agent for the Photographer, agrees to facilitate the payment
        of any Photographer Fees for Photography Services provided, on a
        bi-monthly basis unless otherwise agreed between Gratif and the
        Photographer. In the event that Gratif does not remit such amounts, the
        Photographer will have recourse only against Gratif.
      </p>

      <p>
        <strong>Conditions of Use</strong>
      </p>

      <p>
        <strong>User Conduct</strong>
      </p>

      <p>
        You are solely responsible for all code, video, images, information,
        data, text, software, music, sound, photographs, graphics, messages, or
        other materials ("content") that you upload, post, publish, or display
        (hereinafter, "upload") or email or otherwise use via the Service. The
        following are examples of the kind of content and/or use that is illegal
        or prohibited by Gratif. Gratif reserves the right to investigate and
        take appropriate legal action against anyone who, in Gratif's sole
        discretion, violates this provision, including, without limitation,
        removing the offending content from the Service, suspending or
        terminating the account of such violators, and reporting you to the law
        enforcement authorities. You agree not to use the Service to:
      </p>

      <ul>
        <li>
          Email or otherwise upload any content that:
          <ul>
            <li>
              Infringes intellectual property or other proprietary rights.
            </li>
            <li>You do not have the right to upload under law or contract.</li>
            <li>Contains viruses or other harmful code.</li>
            <li>Poses a privacy or security risk.</li>
            <li>
              Is unsolicited or unauthorized advertising, spam, or other
              solicitation.
            </li>
            <li>
              Is unlawful, harmful, threatening, abusive, harassing, tortious,
              excessively violent, defamatory, vulgar, obscene, pornographic,
              libelous, invasive of privacy, hateful, or racially, ethnically,
              or otherwise objectionable.
            </li>
            <li>
              Is objectionable in Gratif's sole judgment or restricts or
              inhibits others from enjoying the Service.
            </li>
            <li>May expose Gratif or its users to harm or liability.</li>
          </ul>
        </li>
        <li>
          Interfere with or disrupt the Service or its servers or networks.
        </li>
        <li>Violate any applicable laws or regulations.</li>
        <li>
          Impersonate any person or entity, or misrepresent your affiliation.
        </li>
        <li>Solicit personal information from anyone under 18.</li>
        <li>
          Collect email addresses or other contact information for unsolicited
          communications.
        </li>
        <li>
          Advertise or offer to sell or buy goods or services for unauthorized
          business purposes.
        </li>
        <li>
          Further or promote criminal activity or provide instructional
          information about illegal activities.
        </li>
        <li>
          Obtain or attempt to access materials or information not intentionally
          made available through the Service.
        </li>
      </ul>

      <p>
        <strong>Special Notice for International Use; Export Controls</strong>
      </p>

      <p>
        Software (defined below) available in connection with the Service and
        the transmission of applicable data, if any, are subject to United
        States export controls. No Software may be downloaded from the Service
        or otherwise exported or re-exported in violation of U.S. export laws.
        Downloading or using the Software is at your sole risk. Recognizing the
        global nature of the Internet, you agree to comply with all local rules
        and laws regarding your use of the Service, including as it concerns
        online conduct and acceptable content.
      </p>

      <p>
        <strong>Commercial Use</strong>
      </p>

      <p>
        Unless otherwise expressly authorized herein or in the Service, you
        agree not to display, distribute, license, perform, publish, reproduce,
        duplicate, copy, create derivative works from, modify, sell, resell,
        exploit, transfer, or upload for any commercial purposes any portion of
        the Service, use of the Service, or access to the Service.
      </p>
      <p>
        <strong>Apple-Enabled Software Applications</strong>
      </p>

      <p>
        Gratif offers Software applications intended to operate in connection
        with products made commercially available by Apple Inc. (“Apple”), among
        other platforms. With respect to Software made available for your use in
        connection with an Apple-branded product (such Software, “Apple-Enabled
        Software”), the following terms and conditions apply:
      </p>

      <p>
        Gratif and you acknowledge that these Terms of Service are concluded
        between Gratif and you only, and not with Apple. As between Gratif and
        Apple, Gratif, not Apple, is solely responsible for the Apple-Enabled
        Software and its content.
      </p>

      <p>
        You may not use the Apple-Enabled Software in any manner that violates
        the Usage Rules set forth for Apple-Enabled Software in, or otherwise
        conflicts with, the App Store Terms of Service.
      </p>

      <p>
        Your license to use the Apple-Enabled Software is limited to a
        non-transferable license on an iOS Product you own or control, as
        permitted by the Usage Rules set forth in the App Store Terms of
        Service.
      </p>

      <p>
        Apple has no obligation to provide maintenance or support services for
        the Apple-Enabled Software.
      </p>

      <p>
        Apple is not responsible for any product warranties, and any failure of
        the Apple-Enabled Software to conform to warranties is Gratif’s sole
        responsibility.
      </p>

      <p>
        Gratif, not Apple, is responsible for addressing any claims relating to
        the Apple-Enabled Software.
      </p>

      <p>
        In the event of a third-party claim of infringement, Gratif, not Apple,
        is solely responsible.
      </p>

      <p>
        You represent that you are not in a country subject to U.S. Government
        embargo and are not listed on any U.S. Government list of prohibited
        parties.
      </p>

      <p>For questions about the Apple-Enabled Software, contact Gratif.</p>

      <p>
        Gratif and you agree that Apple and its subsidiaries are third-party
        beneficiaries of these Terms of Service regarding the Apple-Enabled
        Software, and Apple has the right to enforce these Terms of Service.
      </p>
      <p>
        <strong>Intellectual Property Rights</strong>
      </p>

      <p>
        <strong>Service Content, Software, and Trademarks</strong>
      </p>

      <p>
        You acknowledge that the Service may contain content or features
        (“Service Content”) protected by copyright, patent, trademark, trade
        secret, or other proprietary rights and laws. Except as expressly
        authorized by Gratif, you agree not to modify, copy, frame, scrape,
        rent, lease, loan, sell, distribute, or create derivative works based on
        the Service or the Service Content, except for your own User Content (as
        defined below). Your use of the Service will not involve data mining,
        robots, scraping, or similar methods. Any use of the Service or the
        Service Content other than as authorized herein is strictly prohibited.
        The Software is the property of Gratif, our affiliates, and our
        partners. You agree not to copy, modify, create derivative works,
        reverse engineer, or otherwise attempt to discover source code. Gratif’s
        name and logos are trademarks of Gratif. Other product and service names
        may be trademarks of their respective owners.
      </p>

      <p>
        <strong>Photography Services</strong>
      </p>

      <p>
        Photographers assign Photograph IP (defined below) in the Photograph
        Content (defined below) created for a particular Booking and Subject to
        Gratif. Once the Booking transaction is complete, Gratif transfers the
        Photograph IP in Purchased Prints to the Subject. The Photographer
        acknowledges that Photograph Content and Photograph IP are protected by
        copyright, trademark, trade secret, or other proprietary rights and
        laws. The Photographer assigns all rights to Gratif. Upon receipt of
        applicable Photographer Fees, Gratif assigns the Photograph Content and
        associated Photograph IP contained in Purchased Prints to the Subject.
        Subjects grant Gratif a license to use Photograph Content and associated
        Photograph IP for marketing and advertising.
      </p>

      <p>
        <strong>Third-Party Material</strong>
      </p>

      <p>
        Gratif is not liable for any third-party content or materials, and you
        acknowledge that Gratif does not pre-screen content. Gratif has the
        right to refuse or remove any content that violates these Terms of
        Service.
      </p>

      <p>
        <strong>User Content Transmitted Through the Service</strong>
      </p>

      <p>
        You represent and warrant that you own all right, title, and interest in
        your User Content. By uploading User Content, you grant Gratif a license
        to use, copy, display, upload, perform, distribute, store, modify, and
        otherwise use your User Content. Subjects may rate a Photographer and
        Photographers may rate a Subject using the Site’s multidimensional
        rating system. Ratings and Reviews may be removed at Gratif’s
        discretion.
      </p>
      <p>
        You acknowledge that any Submissions provided to Gratif are
        non-confidential, and Gratif has the right to use and disseminate these
        Submissions for any purpose without acknowledgment or compensation.
        Gratif may preserve or disclose content if required by law or to protect
        rights, property, or safety.
      </p>

      <p>
        <strong>Partner Referrals and Subsidies</strong>
      </p>

      <p>
        Gratif may work with Partners to refer Subjects and offer Subsidies for
        Photography Services. Subjects may share photos and details with a
        Partner, granting the Partner a license to use the Photograph Content,
        associated Photograph IP, and shoot details.
      </p>

      <p>
        <strong>Photo Submission Policy</strong>
      </p>

      <p>
        Gratif provides photo editing services to Enterprise Partners. If you
        submit photos for Photo Editing Services, you are solely responsible for
        Your Photos. You grant Gratif and Enterprise Partners a license to use,
        display, distribute, and exploit Your Photos for Photo Editing Services
        and promoting Enterprise Partner brands. You represent that Your Photos
        do not infringe third-party rights.
      </p>

      <p>Please review the updated content to ensure it meets your needs.</p>
      <p>
        <strong>Copyright Complaints</strong>
      </p>

      <p>
        Gratif values the intellectual property of others, and we urge our users
        to do the same. If you believe that your work has been copied in a way
        that constitutes copyright infringement or that your intellectual
        property rights have been violated, please notify Gratif of your
        infringement claim following the procedure below. Gratif will process
        and investigate notices of alleged infringement, taking appropriate
        actions under the Digital Millennium Copyright Act (“DMCA”) and other
        applicable intellectual property laws. Notifications of claimed
        copyright infringement should be emailed to Gratif’s Agent at
        care@gratif.in (Subject line: “DMCA Takedown Request”).
      </p>

      <p>
        To be effective, the notification must be in writing and contain the
        following information:
      </p>

      <ul>
        <li>
          An electronic or physical signature of the person authorized to act on
          behalf of the owner of the copyright or other intellectual property
          interest.
        </li>
        <li>
          A description of the copyrighted work or other intellectual property
          you claim has been infringed.
        </li>
        <li>
          A description of where the material you claim is infringing is located
          on the Service, with enough detail to locate it.
        </li>
        <li>Your address, telephone number, and email address.</li>
        <li>
          A statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright or intellectual property owner,
          its agent, or the law.
        </li>
        <li>
          A statement by you, made under penalty of perjury, that the
          information in your Notice is accurate and that you are the copyright
          or intellectual property owner or authorized to act on the owner’s
          behalf.
        </li>
      </ul>

      <p>
        <strong>Counter-Notice</strong>
      </p>

      <p>
        If you believe that your User Content, which was removed or disabled, is
        not infringing, or you have authorization from the copyright owner, the
        owner’s agent, or pursuant to the law, to upload and use the content in
        your User Content, you may send a written counter-notice with the
        following information to the Copyright Agent:
      </p>

      <ul>
        <li>Your physical or electronic signature.</li>
        <li>
          Identification of the content that has been removed or disabled and
          its location before removal or disabling.
        </li>
        <li>
          A statement that you have a good faith belief that the content was
          removed or disabled as a result of mistake or misidentification.
        </li>
        <li>
          Your name, address, telephone number, and email address, along with a
          statement consenting to Bangalore jurisdiction and accepting service
          of process from the notifying party.
        </li>
      </ul>

      <p>
        If a counter-notice is received, Gratif will send a copy to the original
        complaining party, allowing them 10 business days to replace or cease
        disabling the content. Unless the copyright owner files a court order,
        the content may be restored at Gratif's discretion within 10 to 14
        business days or more after receiving the counter-notice.
      </p>

      <p>
        <strong>Repeat Infringer Policy</strong>
      </p>

      <p>
        In line with the DMCA and applicable law, Gratif reserves the right to
        terminate users deemed repeat infringers, at its sole discretion. Gratif
        may also limit access or terminate memberships of users infringing any
        intellectual property rights, regardless of repeat infringement.
      </p>
      <p>
        <strong>Third Party Websites</strong>
      </p>

      <p>
        The Service may provide links or access to other sites and resources.
        Gratif has no control over such sites and resources, disclaims
        responsibility, and does not endorse them. You acknowledge and agree
        that Gratif is not liable for any damage or loss caused by or in
        connection with the use of or reliance on any content, events, goods, or
        services available on or through such sites or resources. Any dealings
        with third parties are between you and the third party, and Gratif is
        not liable for any loss or claim against such third party.
      </p>

      <p>
        <strong>Social Networking Services</strong>
      </p>

      <p>
        You may log in to the Service via third-party services like Facebook or
        Twitter (“Social Networking Services”). By integrating these services,
        you authorize us to access information provided to these services,
        handled per our Privacy Policy. Gratif is not responsible for
        third-party privacy practices and actions, and the inclusion of features
        does not imply endorsement.
      </p>

      <p>
        <strong>Indemnity and Release</strong>
      </p>

      <p>
        You agree to release, indemnify, and hold Gratif, and, if you are a
        Photographer, hold Subjects, and their affiliates, officers, employees,
        directors, and agents (“Indemnitees”) harmless from any losses, damages,
        expenses, including reasonable attorneys’ fees, rights, claims, actions,
        or injury arising from or related to your use of the Service, any User
        Content, your connection to the Service, or your violation of these
        Terms of Service or another's rights. However, you have no obligation to
        indemnify an Indemnitee for any liability incurred due to their action
        or inaction.
      </p>

      <p>
        <strong>Disclaimer of Warranties</strong>
      </p>

      <p>
        Your use of the Service is at your sole risk. The Service is provided on
        an “as-is” and “as-available” basis. Gratif expressly disclaims all
        warranties of any kind, whether express, implied, or statutory,
        including, but not limited to, the implied warranties of
        merchantability, fitness for a particular purpose, title, and
        non-infringement.
      </p>

      <p>
        Gratif makes no warranty that (i) the Service will meet your
        requirements, (ii) the Service will be uninterrupted, timely, secure, or
        error-free, (iii) the results obtained from the use of the Service will
        be accurate or reliable, or (iv) the quality of any products, services,
        information, or other material obtained through the Service will meet
        your expectations.
      </p>

      <p>
        <strong>Limitation of Liability</strong>
      </p>

      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT Gratif WILL NOT BE LIABLE FOR
        ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR
        DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
        LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF Gratif
        HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON
        CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING
        FROM: (I) THE USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST OF
        PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS,
        DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED
        OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III)
        UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV)
        STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V) ANY
        OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL Gratif’S TOTAL
        LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE
        AMOUNT YOU HAVE PAID Gratif IN THE LAST SIX (6) MONTHS. SOME
        JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN
        WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
        CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET
        FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU.
        IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE
        TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE
        OF THE SERVICE.
      </p>

      <p>
        <strong>Termination</strong>
      </p>

      <p>
        You acknowledge that Gratif, at its sole discretion, reserves the right
        to suspend or terminate your account (or any part thereof) or use of the
        Service. Gratif may remove and discard any content within the Service
        for various reasons, including, but not limited to, lack of use or a
        belief that you have violated the letter or spirit of these Terms of
        Service. Suspected fraudulent, abusive, or illegal activity that could
        lead to the termination of your Service use may be referred to
        appropriate law enforcement authorities. Additionally, Gratif may, at
        its sole discretion and at any time, discontinue providing the Service
        or any part thereof, with or without notice. You agree that termination
        of your access to the Service under any provision of these Terms of
        Service may occur without prior notice. You acknowledge and agree that
        Gratif may promptly deactivate or delete your account, along with all
        related information and files, and/or bar any further access to such
        files or the Service. Furthermore, you agree that Gratif shall not be
        liable to you or any third party for any termination of your access to
        the Service.
      </p>

      <p>
        <strong>User Disputes</strong>
      </p>

      <p>
        You accept sole responsibility for your interactions with any other user
        in connection with the Service, and Gratif assumes no liability or
        responsibility in that regard. While Gratif reserves the right, it is
        not obligated to become involved in disputes between you and any other
        user of the Service.
      </p>

      <p>
        <strong>General</strong>
      </p>

      <p>
        These Terms of Service constitute the entire agreement between you and
        Gratif, governing your use of the Service and superseding any prior
        agreements. You may also be subject to additional terms and conditions
        when using affiliate or third-party services, third-party content, or
        third-party software. Gratif's failure to exercise or enforce any right
        or provision of these Terms of Service does not constitute a waiver of
        such right or provision. If any provision is found invalid by a court of
        competent jurisdiction, the parties agree that the court should strive
        to give effect to the parties' intentions as reflected in the provision,
        while other provisions of these Terms of Service remain in full force
        and effect. Regardless of any statute or law to the contrary, any claim
        or cause of action arising from the use of the Service or these Terms of
        Service must be filed within one (1) year after the claim or cause of
        action arose, or be forever barred. A printed version of this agreement
        and any notice given in electronic form is admissible in judicial or
        administrative proceedings, with the same effect and subject to the same
        conditions as other business documents and records generated and
        maintained in printed form. You may not assign these Terms of Service
        without the prior written consent of Gratif, but Gratif may assign or
        transfer these Terms of Service, in whole or in part, without
        restriction. The section titles in these Terms of Service are for
        convenience only and have no legal or contractual effect. Notices to you
        may be made via email or regular mail. The Service may also provide
        notices of changes to these Terms of Service or other matters by
        displaying notices or links to notices generally on the Service.
      </p>

      <p>
        <strong>Your Privacy</strong>
      </p>

      <p>
        At Gratif, we prioritize the privacy of our users. For details, please
        refer to our <a href="link-to-privacy-policy">Privacy Policy</a>. By
        using the Service, you express consent to our collection and use of
        personal data as outlined therein.
      </p>

      <p>
        <strong>Contact Us</strong>
      </p>

      <p>
        For reporting violations of these Terms of Service or posing any
        questions regarding these Terms of Service, the Service, or any other
        matters, please contact us via{" "}
        <a href="https://www.gratif.in/contact-us">
          https://www.gratif.in/contact-us
        </a>
        .
      </p>
    </div>
  );
};

export default TermsAndConditions;
