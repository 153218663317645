import React from "react";
import { Link } from "react-router-dom";
import "./NotFoundPage.css";

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <h1 className="not-found-title">Oops!</h1>
      <p className="not-found-message">
        The page you're looking for doesn't exist.
      </p>
      <Link to="/" className="not-found-button">
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
