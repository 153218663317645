import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import AppUrls from "../../api/AppUrls";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    apiClient
      .request(AppUrls.contactUsUrl, "POST", {
        name: name,
        email: email,
        phone: phone,
        type: "From website",
        message: message,
      })
      .then((response) => {
        if (response) {
          toast.success("Message sent successfully");
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Container style={{ marginTop: "50px" }}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={8}>
            <h1>Contact Us</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                controlId="formGroupName"
                style={{ marginBottom: "20px" }}
              >
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupEmail"
                style={{ marginBottom: "20px" }}
              >
                <Form.Label>Email address *</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupPhone"
                style={{ marginBottom: "20px" }}
              >
                <Form.Label>Phone Number *</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter phone number"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupMessage"
                style={{ marginBottom: "20px" }}
              >
                <Form.Label>Message *</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                disabled={isLoading}
                style={{
                  backgroundColor: "var(--orange-color)",
                  borderColor: "var(--orange-color)",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {isLoading ? "Sending Message..." : "Send Message"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default ContactUs;
