const RefundPage = () => {
  return (
    <div className="container">
      <h1 className="mt-3">Cancellation Policy</h1>
      <p>
        Photography, unlike many other businesses, involves professionals
        blocking a calendar day for a specific shoot. This commitment means that
        once a day is reserved for your shoot, it cannot be easily filled by
        other clients. Last-minute cancellations not only impact the
        photographer's livelihood but also result in an entire calendar day left
        unutilized. We appreciate your understanding of these unique
        considerations and thank you for your cooperation.
      </p>

      <h2>Cancellation:</h2>
      <p>
        Feel free to cancel within 48 hours of booking for a full refund. You’ve
        got one opportunity to reschedule! Just a friendly reminder to
        double-check the address, as changes aren’t possible later.
      </p>

      <h2>Reschedule:</h2>
      <p>
        If you have a one-off reschedule request, contact our customer service
        at least 7 days before the scheduled shoot. We'll attempt to arrange a
        change in slot at no additional cost.
      </p>

      <h2>Professional No-Show or Unresponsiveness:</h2>
      <p>
        In the unfortunate event where the professional doesn't show up or is
        unresponsive to your calls, please contact Gratif Customer Care at{" "}
        <a href="mailto:care@gratif.in">care@gratif.in</a> or{" "}
        <a href="tel:+917019592672">+91 7019592672</a>. We will assist you in
        finding another professional or send a backup team. If we are unable to
        arrange either, a full refund will be initiated.
      </p>
    </div>
  );
};

export default RefundPage;
